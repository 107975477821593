import React, { useState, useEffect, useContext } from 'react'
import {
  Typography, 
  Menu,
  MenuItem,
  Badge,
  Box
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import { AppContext } from 'core/context';
// import { getNotificationList } from 'services/notifications';

const Notifications = () => {

  const [notificationEl, setNotificationEl] = useState(null);
  // const [notification, setNotification] = useState([]);
  const { paymentNotification: notification } = useContext(AppContext);

  useEffect(() => {
    // getNotifications();
  }, []);

  const showNotifications = (event) => {
    setNotificationEl(event.currentTarget);
  }

  const closeNotifications = () => {
    setNotificationEl(null);
  }

  // function getNotifications() {
  //   getNotificationList().then(({response}) => {
  //     if(response?.error_code === 0){
  //       setNotification([...( response?.data?.notifications || [])])
  //     }
  //   });
  // }

  return (
    <>
      <IconButton
        size="large"
        aria-label="notifications"
        aria-controls="notification-appbar"
        aria-haspopup="true"
        onClick={showNotifications}
        color="inherit"
      >
        <Badge badgeContent={notification?.length || 0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="notification-appbar"
        anchorEl={notificationEl}  
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        open={Boolean(notificationEl)}
        onClose={closeNotifications}
      >
        <Box sx={{ 
          overflowY: 'scroll',
            maxHeight: '50vh',
          }}>

          {notification.map((text, i) => (
            <MenuItem onClick={closeNotifications} key={i}>
              <Typography variant="subtitle1"  key={i}>
                {text}
              </Typography>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  );
}
 
export default Notifications;
