import { AuthContext } from "core/context";
import React, { useContext } from "react"
import { Navigate } from "react-router-dom";
import { isLinkAccessable } from "core/utils/common"; 
import AppLoading from "core/appLoading";

const AuthenticatedRoute = ({ children, rolesAssigned=[] }) => {
  const { isUserLoggedIn, data = {} } = useContext(AuthContext);
  const { roles = [] } = data;

  if(!isUserLoggedIn) {
    return <AppLoading />
  }
  // redirect to unauthorized page id role is invlaid
  if(rolesAssigned.length > 0 && !(isLinkAccessable(rolesAssigned, roles))){
    return <Navigate to="/overview/" replace={true} />
  }
  return children;
};

export default AuthenticatedRoute;
