import React from 'react';
import { Card, CardContent, Typography, Tabs, Tab } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PasswordIcon from '@mui/icons-material/Password';
import { Box } from '@mui/system';
import Account from './Account';
import ChangePassword from './ChangePassword';
import Documents from './documents/';
// import { AuthContext } from 'core/context';

const Profile = () => {
  const [value, setValue] = React.useState(0);
  // const { inmateId } = useContext(AuthContext);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{mb: 1}}>
          Profile
        </Typography>
        <Tabs value={value} onChange={handleChange} >
          <Tab icon={<AccountCircleIcon />} iconPosition="start" label="Account" />
          <Tab icon={<PasswordIcon />} iconPosition="start" label="Change Password" />
          <Tab icon={<DocumentScannerIcon />} iconPosition="start" label="Documents" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Account />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChangePassword />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Documents />
        </TabPanel>
      </CardContent>
     </Card> 
  );
}
 
export default Profile;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
