import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className='not-found-container'>
      <h1>Page you were looking for is missing.</h1>
      <p className="zoom-area">The Page youe are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
      <section className="error-container">
        <span>4</span>
        <span><span className="screen-reader-text">0</span></span>
        <span>4</span>
      </section>
      <div className="link-container">
        <Link to="/overview/" className={"more-link"}>Visit Dashboard</Link>
      </div>
    </div>
  );
}
 
export default NotFound;
