import React from 'react';
import {
 ListItemButton,
 ListItemIcon,
 Icon,
 ListItemText,
 Collapse
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NavList from './NavList';

const CollapseableNavList = ({ menuItem }) => {

    const  {name, icon, items } = menuItem;

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    
    return(
        <>
            <ListItemButton onClick={handleClick} sx={{ my: 0, pb: 1}}>
                <ListItemIcon>
                <Icon>{icon}</Icon>
                </ListItemIcon>
                <ListItemText
                 primary={name}
                    primaryTypographyProps={{
                        fontSize: 14,
                        lineHeight: '18px',
                        color: '#000',
                      }}
                />
                {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <NavList menuItems={items} sx={{pl: 4}} />
            </Collapse>
        </>
    );
};
 
export default CollapseableNavList;
