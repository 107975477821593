import React, {useState} from 'react';

const defaults = {
  isSideDrawerOpen: true,
  isApiRequestInProgress: false,
  paymentNotification: [],
};
const AppContext = React.createContext(defaults);

const AppContextProvider = (props) => {
  const [context, setContext] = useState({...defaults});

  const updateAppContext = (contextValues={}) => {
    setContext({
      ...context,
      ...contextValues
    });
  }

  return (
    <AppContext.Provider value={{...context, updateAppContext}}>
      {props.children}
    </AppContext.Provider>
  );
}
 
export default AppContextProvider;
export { AppContext };
