import { requestHandler } from 'core/services/httpRequest';
// import { dataFetcher } from 'core/services/requestHandler';   

const checkNoticeStatus = async (data = {}, config = {}) => {
  return requestHandler.GET({url: '/user/notice'});
};

const sendCheckoutNotice = async (body = {}, config = {}) => {
  return requestHandler.POST({url: '/user/notice', body});
};

const updateCheckoutNotice = async (body = {}, config = {}) => {
  return requestHandler.PUT({url: '/user/notice', body});
};

const cancelCheckoutNotice = async () => {
  return requestHandler.DELETE({url: '/user/notice'});
};

export { checkNoticeStatus, sendCheckoutNotice, cancelCheckoutNotice, updateCheckoutNotice };
