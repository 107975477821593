import React from 'react';
import { Grid, Paper, Box, TextField, Button } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { changePassword } from 'core/services/profile';
import { useSnackbar } from 'notistack';

const defaultFormValues = {
  oldPassword: '',
  newPassword:'',
  confirmNewPassword: ''
}
const ChangePassword = () => {

  /** Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState: { errors }, reset, watch } = useForm({
    mode: 'onTouched',
    defaultValues: {...defaultFormValues}
  });

  const onSubmit = (data) => {
    const postData = { ...data };
    delete postData['confirmNewPassword'];
    changePassword({...postData }).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      }else{
        enqueueSnackbar(('Password updated sucessfully'), {variant: 'success'});
        reset({...defaultFormValues})
      }
    });
  }

  return ( 
    <Paper  sx={{mt: 1,boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'}}>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Box noValidate component="form"  onSubmit={handleSubmit(onSubmit)} sx={{  p:4,  }} >
          {/* <Typography variant="subtitle2"  sx={{mt: 1, mb: 3}}>
            Update account details
          </Typography> */}
          <Grid container columnSpacing={2} rowSpacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12}  >
              <Controller
                control={control}
                name="oldPassword"
                id="old_password"
                rules={{
                  required: "Enter your old password",
                  minLength: { value: 6, message: 'Password must be atlease 6 characters' },
                }}
                render={({field }) => (
                  <TextField
                    type="password"
                    {...field}
                    margin="normal"
                    fullWidth
                    size="small"
                    autoFocus
                    label="Old Password"
                    required
                    error={errors.oldPassword?.type === 'required'}
                    helperText={errors.oldPassword?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Controller
                control={control}
                name="newPassword"
                id="new_password"
                rules={{
                  required: "Enter your new password", 
                  minLength: { value: 6, message: 'Password must be atlease 6 characters' },
                  // validate: (value) => value === watch('confirmNewPassword')
                }}
                render={({field}) => (
                  <TextField
                    {...field}
                    type="password"
                    margin="normal"
                    label="New Password"
                    required
                    error={errors.newPassword}
                    helperText={errors.newPassword?.message}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Controller
                control={control}
                name="confirmNewPassword"
                id="confirmNewPassword"
                rules={{
                  required: "Enter your new password again",
                  minLength: { value: 6, message: 'Password must be atlease 6 characters' },
                  validate: (value) => value === watch('newPassword') || "Password fields don't match"
                }}
                render={({field}) => (
                  <TextField
                    {...field}
                    type="password"
                    margin="normal"
                    label="Confirm New Password"
                    required
                    error={errors.confirmNewPassword}
                    helperText={errors.confirmNewPassword?.message}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="right"
              alignItems="center"
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 6, mb: 2 }}
                color="success"
              >
                Update Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
}
 
export default ChangePassword;
