import React, { useCallback, useState } from 'react'

export function useFileUpload({ onStarting } = {}) {
	const [fileName, setFileName] = useState(undefined)
	const uploadFile = useCallback((event) => {
		if (!event?.target?.files[0]) {
			return
		}
		setFileName(event?.target.name)
		onStarting(event?.target?.files[0])
	}, [onStarting])

	return [uploadFile];
};
