import { requestHandler } from 'core/services/httpRequest';
// import { dataFetcher } from 'core/services/requestHandler';   

const getIssue = async ({status}) => {
  return requestHandler.GET({url: `/issue/${status}`,});
};

const getIssueDetails = async (issueId) => {
  return requestHandler.GET({url: `/issue/${issueId}/details`});
};

const createIssue = async (body = {}, config = {}) => {
  return requestHandler.POST({url: '/issue', body});
};

const updateIssue = async (body = {}, config = {}) => {
  return requestHandler.PUT({url: '/issue', body});
};


export { getIssue, getIssueDetails, createIssue, updateIssue };
