import React, { useContext } from 'react';
import { List } from '@mui/material';
import NavListItem from './NavListItem';
import CollapseableNavList from './CollapseableNavList';
import { AuthContext } from 'core/context';
import { isLinkAccessable } from 'core/utils/common';

const NavList = ({ isSideDrawerOpen = false, menuItems, sx = {} }) => {

  const authContext = useContext(AuthContext);
  const { roles = [] } = authContext?.data;

  return (
      <List>
        {menuItems.map((menuItem, index) => {
          if (menuItem?.group && (!menuItem.hasOwnProperty('roles') || (menuItem?.roles && Array.isArray(menuItem.roles) && isLinkAccessable(menuItem.roles, roles))) ) {
            return <CollapseableNavList menuItem={menuItem} key={index} />
          // } else if( menuItem?.subHeading){
          //   return isSideDrawerOpen ? (
          //     <ListItemText
          //       key={index} 
          //       primary={menuItem.name}
          //       primaryTypographyProps={{
          //         fontSize: 16,
          //         fontWeight: 'medium',
          //         lineHeight: '20px',
          //         texttransform: 'uppercase',
          //         // mb: '2px',
          //       }}
          //       // secondary="Authentication, Firestore Database, Realtime Database, Storage, Hosting, Functions, and Machine Learning"
          //       // secondaryTypographyProps={{
          //       //   noWrap: true,
          //       //   fontSize: 12,
          //       //   lineHeight: '16px',
          //       //   color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
          //       // }}
          //       sx={{ mt: 2, pl: 2 }}
          //     />
          //   ) : null;
          }else if(menuItem.url && (!menuItem.hasOwnProperty('roles') || (menuItem?.roles && Array.isArray(menuItem.roles) && isLinkAccessable(menuItem.roles, roles))) ){
            return <NavListItem menuItem={menuItem} key={index} sx={sx} />
          }
          return null;
        })}
      </List>
  );
}
 
export default NavList;
