import { Backdrop, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useAxiosLoader } from './AxiosLoader';

const Loader = () => {
  const [loading] = useAxiosLoader();
  
  return(
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1300 }}
      open={loading}
    >
     <Button variant="contained" color='primary' sx={{pointerEvents: 'none'}}  ><CircularProgress sx={{color: '#fff', mr: 1}} /> Loading...</Button>
    </Backdrop>    
  );
};

export default React.memo(Loader);
