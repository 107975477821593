// import { dataFetcher } from 'core/services/requestHandler';   
import { requestHandler } from 'core/services/httpRequest'; 

const getPendingPayments = async () => {
  return requestHandler.GET({url: '/payment/unpaid'});
};

export function getPaymentTypes(){
  return requestHandler.GET({url: '/payment/options'});
}

const confirmRentPayment = async (body = {}, config = {}) => {
  return requestHandler.POST({url: '/payment/approval', body});
};

const getInmatePaymentHistory = async (data = {}, config = {}) => {
  return requestHandler.GET({url: '/payment/history'});
};

const getPaymentModes = async (data = {}, config = {}) => {
  return requestHandler.GET({url: '/payment/modes'});
};

const payRentOnline = async (paymentId, config = {}) => {
  return requestHandler.GET({url: `/payment/${paymentId}/payOnline`, ...config});
};

const fetchPaymentDetails = async (paymentId, config = {}) => {
  return requestHandler.GET({url: `/payment/${paymentId}/details`, ...config});
};

export { getPendingPayments, confirmRentPayment, getInmatePaymentHistory, getPaymentModes, payRentOnline, fetchPaymentDetails };
