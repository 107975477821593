import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      light: '#54caca',
      main: '#009a9a',
      dark: '#006a6b',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#a3cfd4',
      main: '#739ea3',
      dark: '#457074',
      contrastText: '#ffffff',
    },
  },
});

export default theme;
