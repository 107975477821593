import React from 'react';

const Dashboard = () => {
  console.log('Dashboard Component');
  return (
    <>
      <h2 style={{textAlign: 'center'}}>Dashboard</h2>
      <div style={{textAlign: 'center'}}>

       <img src={`${process.env.REACT_APP_PATH}/assets/images/website-construction.jpg`} alt="dashboard" style={{width: 'auto', height: '70vh'}}/>
      </div>
    </>
  );
}
 
export default Dashboard;
