import React from 'react';
import { SnackbarProvider } from 'notistack';
import { AppContextProvider, AuthContextProvider } from 'core/context/';
import AppRouter from 'core/routes';
import {  Button } from '@mui/material';

import './App.scss';

function App() {

  // add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

  return (
    <AppContextProvider>
      <AuthContextProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          preventDuplicate
          ref={notistackRef}
          action={(key) => (
              <Button onClick={onClickDismiss(key)} size="small" sx={{color: "#fff"}}>
                  Dismiss
              </Button>
          )}
          autoHideDuration={4000}
        >
          <AppRouter></AppRouter>
        </SnackbarProvider>
      </AuthContextProvider>
    </AppContextProvider>
  );
}

export default React.memo(App);
