import { AddScript } from "utils/common";
import { PAYMENT_GATEWAY } from "utils/appConstants";

const PaytmCheckout = function(successHandler, failureHandler, notifyMerchantHandler){
  let Paytm;
  // Show Paytm window
  async function launchPaymentGateway(txnObj) {
    const { merchantId } = txnObj;
    const checkoutJSScriptURL = `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/${merchantId}.js`;
    // Add Paytm script
    AddScript(checkoutJSScriptURL).then(() => {
      Paytm = window.Paytm;
      const config = buildPaymentData(txnObj);

      if(Paytm && Paytm.CheckoutJS){
        if(Paytm && Paytm.CheckoutJS.init){
          // initialze configuration using init method 
          initCheckoutJs(config);
        }else{
          Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
            // initialze configuration using init method 
            initCheckoutJs(config)
          });
        }
      } 
    });
  }

  function initCheckoutJs(config) {
    Paytm.CheckoutJS.init(config).then(function onSuccess() {
      // after successfully updating configuration, invoke JS Checkout
      Paytm.CheckoutJS.invoke();
    }).catch(function onError(error){
      console.log("error => ",error);
      // paymentFailed();
    });

  }

  function buildPaymentData(txnObj) {
    const { orderId, transactionToken, amount, callbackUrl } = txnObj;
    // const callbackUrl = null;
    const config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
        "orderId": orderId.toString(), /* update order id */
        "token": transactionToken.toString(), /* update token value */
        "tokenType": "TXN_TOKEN",
        "amount": amount, /* update amount */
      },
      "merchant":{
        "name": "PG Manager",
        redirect: false
      },
      "handler": {
        "notifyMerchant": (eventName, data) => {
          // console.log("notifyMerchant handler function called");
          // console.log("eventName => ",eventName);
          // console.log("data => ",data);
          if(typeof notifyMerchantHandler === 'function') {
            notifyMerchantHandler(eventName, orderId, data);
          }
        }, 
        transactionStatus: (data) => {
          updatePaymentResponse(data, callbackUrl); 
          if(data['STATUS'] === 'TXN_SUCCESS'){
            paymentSuccess(data, PAYMENT_GATEWAY.PAYTM);
          } else if(data['STATUS'] === 'TXN_FAILURE'){
            paymentFailed(data, PAYMENT_GATEWAY.PAYTM);
          } 
          Paytm.CheckoutJS.close();
          
        } 
      }
    }
    return config;
  }

  function paymentSuccess(data, gatewayProvider) {
    if(typeof successHandler === 'function'){
      successHandler(data, gatewayProvider);
    }
  }

  function paymentFailed(data, gatewayProvider) {
    if(typeof failureHandler === 'function'){
      failureHandler(data, gatewayProvider);
    }
  }
  
  function updatePaymentResponse(paytmResp, callbackUrl) {
    var xhr = new XMLHttpRequest();
    let params = Object.keys(paytmResp).map(function(k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(paytmResp[k]);
    }).join('&')
    xhr.open("POST", callbackUrl, true);
    //Send the proper header information along with the request
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {//Call a function when the state changes.
        if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
        }
    }
    xhr.send(params);
  }
  
  return {
    launch: launchPaymentGateway
  }
};
 
export default PaytmCheckout;

