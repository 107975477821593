import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Divider, FormLabel, Paper, Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import { updateFoodData, getFoodData} from 'services/foodRegister';
import { useSnackbar } from 'notistack';
import { addDays, format } from 'date-fns';

const FoodRegister = () => {

  const defaultFoodData = {
    "today": {
      "breakfast": "M",
      "lunch": "M",
      "dinner": "M"
    },
    "tomorrow": {
      "breakfast": "M",
      "lunch": "M",
      "dinner": "M"
    }
  };

  const FoodSelectValues = [
    { name: "Yes", value: 'Y'},
    { name: "May Be", value: 'M'},
    { name: "No", value: 'N'}
  ];

  const foodTimings = [
    {name: "Breakfast", value: "breakfast"},
    {name: "Lunch", value: "lunch"},
    {name: "Dinner", value: "dinner"}
  ];

  const FoodDates = [
    { day: "Today", 
      value: "today",
      foodTimings: [...foodTimings]
    },
    { day: "Tomorrow", 
      value: "tomorrow",
      foodTimings: [...foodTimings]
    }
  ]


  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const [foodData, setFoodData] = useState({...defaultFoodData});

  useEffect(() => {
    getSavedFoodData();
  }, []);

  function getSavedFoodData() {
    getFoodData().then(({response, error}) => {
      if(error){
        enqueueSnackbar((response?.message || error), {variant: 'error'});
        setFoodData({});
      } else {
        let tempData = {};
        (response || []).forEach(data => {
          const { serveDate } = data;
          delete['serveDate']; 
          const day = serveDate === format(new Date(), 'dd-MM-yyyy') ? 'today' : 'tomorrow';
          tempData[day] = {...data};
        });
        setFoodData({ ...tempData || {} })
      }
    });
  }

  function saveFoodData(data) {
    updateFoodData(data).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        enqueueSnackbar((response?.message || 'Successfully updated the food data'), {variant: 'success'});
      }
    });
  }

  const handleRadioChange = (event, day, type) => {
    let tempData = {
      ...foodData
    };
    tempData[day] = { ...tempData[day]};
    tempData[day][type] = event.target.value;
    setFoodData({...tempData});

    const postData = [
      {
        ...tempData.today,
        serveDate: format(new Date(), 'dd-MM-yyyy')
      },
      {
        ...tempData.tomorrow,
        serveDate: format(addDays(new Date(),1), 'dd-MM-yyyy')
      }
    ];
    saveFoodData(postData);
  }
  return (
    <Paper sx={{ minWidth: 275, py: 2, px: 3, minHeight: "calc(100vh - 100px )" }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{mb: 1}}>
        Food Register
      </Typography>
      <Divider />
      <Box noValidate  sx={{ mt: 2 }} >
        <Grid container spacing={4}>
          {FoodDates.map(({day, value: dayValue, foodTimings}) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={dayValue}>
              <Card sx={{ minWidth: 275 }} >
                <CardContent>
                  <Typography variant="h6" component="div" sx={{mb: 1}}>
                    {day}
                  </Typography>
                  <Divider />
                  {foodTimings.map(({name, value}) => (
                    <Grid container   sx={{ mt: 1.2 }} key={value} >
                      <Grid item xs={4} sm={4} md={4} lg={4} >
                        <FormLabel component="legend" sx={{mt:1}}>{name}</FormLabel>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8} >
                        <RadioGroup row aria-label="position" name="position" value={(foodData[dayValue] && foodData[dayValue][value]) || "M"} defaultValue={(foodData[dayValue] && foodData[dayValue][value]) || "M"} onChange={(e) => handleRadioChange(e,dayValue, value)}>
                          {FoodSelectValues.map(({name, value}) => (
                            <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio />}
                            label={name}
                            labelPlacement="end"
                            sx={{mr:3}}
                            />
                            ))}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  ))}
                </CardContent>
              </Card>   
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}
 
export default FoodRegister;
