import { requestHandler } from 'core/services/httpRequest';
// import { dataFetcher } from 'core/services/requestHandler';   

const getRentReceipts = async () => {
  return requestHandler.GET({url: '/payment/paid'});
};

const SendRentReceiptToMail = async (paymentId) => {
  return requestHandler.GET({url: `/payment/${paymentId}/emailReceipt`});
};

const getRentReceiptDetails = async (paymentId) => {
  return requestHandler.GET({url: `/payment/${paymentId}/receipt`});
};

export function fileDownloader(paymentId){
  return requestHandler.FILE({url: `/payment/${paymentId}/downloadReceipt`});
}

export { getRentReceipts, SendRentReceiptToMail, getRentReceiptDetails };
