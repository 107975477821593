import React, { useState } from 'react';  
import { Avatar, Button, CssBaseline, TextField, Box, Grid, Typography, Paper } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { resetPassword, sendPasswordResetOTP } from 'core/services/auth';
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from 'notistack';

const defaultValues = {
  mobile: '',
  otp: '',
  newPassword: '',
  confirmNewPassword: '',
}
export default function PasswordReset() {
  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const [isOTPSent, setIsOTPSent] = useState(false);
  const navigate = useNavigate();
  const { handleSubmit, control, formState: { errors }, reset, watch } = useForm({defaultValues});

  function clearForm() {
    reset(defaultValues)
  }
  
  function passwordReset(data) {
    delete data['confirmNewPassword'];
    resetPassword(data).then(({ response, error }) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        enqueueSnackbar('Password reset successful', {variant: 'success'});
        clearForm();
        navigate('/signin/');
      }
    });
  }

  function sendOTP(data) {
    sendPasswordResetOTP(data.mobile).then(({ response, error }) => {
      if(error){
        if(error?.includes('OTP has already'))
          setIsOTPSent(true)
        else
          setIsOTPSent(false);
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setIsOTPSent(true)
        enqueueSnackbar('OTP has been sent to your registed mobile number', {variant: 'success'});
      }
    });
  }

  const onSubmit = (data) => {
    if(isOTPSent){
      passwordReset(data);
    }else if(data.mobile){
      sendOTP(data);
    }

  
  };

  return (
      <Grid container component="main" sx={{ height: '100vh' }} >
        <CssBaseline />
        {/* <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: 'url(/assets/images/login-banner.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        /> */}
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{ m: 2, bgcolor: 'primary.main', width: 80, height: 80 }}
              alt="PG Manager Logo" src={`${process.env.REACT_APP_PATH}/assets/images/login-logo.png`}
              className='login-logo'
            />
            <Typography component="h1" variant="h5">
              Forgotten Password?
            </Typography>
            <Typography  variant="caption" color="text.secondary">
             Enter your registered mobile number to reset your password
            </Typography>
            {/* <Box noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>  */}
            <Grid container item xs={12} sm={8} md={5} lg={4} xl={3} noValidate onSubmit={handleSubmit(onSubmit)} component="form" mt={1.6}>
              <Grid item xs={12} sm={12} md={12} >
                <Controller
                  control={control}
                  name="mobile"
                  id="mobile"
                  autoComplete="mobile"
                  rules={{ required: "Enter your registered mobile number",
                    pattern: {
                      value: /^[0-9]{10}$/i,
                      message: "Enter a valid 10 digit registered mobile number",
                    },
                  }}
                  render={({field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      autoFocus
                      label="Mobile Number"
                      required
                      disabled={isOTPSent}
                      error={!!(errors?.mobile)}
                      helperText={errors.mobile?.message}
                    />
                  )}
                />
              </Grid>
              {isOTPSent ? (
                <>
                  <Grid item xs={12} sm={12} md={12} >
                    <Controller
                      control={control}
                      name="otp"
                      id="otp"
                      rules={{ required: "Enter otp",
                        pattern: {
                          value: /^[0-9]{6}$/i,
                          message: "Enter valid otp",
                        },
                      }}
                      render={({field }) => (
                        <TextField
                          {...field}
                          margin="normal"
                          fullWidth
                          autoFocus
                          label="OTP"
                          required
                          error={!!(errors?.otp)}
                          helperText={errors?.otp?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <Controller
                      control={control}
                      name="newPassword"
                      id="newPassword"
                      rules={{ required: "Enter New Password",
                          minLength: { value: 8, message: 'Password must be atleast 8 characters' },
                      }}
                      render={({field }) => (
                        <TextField
                          {...field}
                          margin="normal"
                          fullWidth
                          label="New Password"
                          required
                          type="password"
                          error={!!(errors?.newPassword)}
                          helperText={errors?.newPassword?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <Controller
                      control={control}
                      name="confirmNewPassword"
                      id="confirmNewPassword"
                      rules={{ required: "Confirm New Password",
                          minLength: { value: 8, message: 'Password must be atleast 8 characters' },
                          validate: (value) => value === watch('newPassword') || "Password fields don't match"
                      }}
                      render={({field }) => (
                        <TextField
                          {...field}
                          margin="normal"
                          fullWidth
                          label="Confirm New Password"
                          required
                          type="password"
                          error={!!(errors?.confirmNewPassword)}
                          helperText={errors?.confirmNewPassword?.message}
                        />
                      )}
                    />
                  </Grid>
                </>
              ): null}

              <Grid container item xs={12} justifyContent={isOTPSent ? "space-between": 'center'} alignItems="center" >
                {isOTPSent ? <Button type="button" variant="outlined" sx={{ mt: 3, mb: 2 }} onClick={clearForm}>
                  Clear
                </Button>: null}
                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                  {isOTPSent ? 'Reset Password' : 'Validate'}
                </Button>
              </Grid>

              <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  component={Link}
                  to="/signin/"
                  color="secondary"
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
  );
}
