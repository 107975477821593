import React, { useState, useEffect, useCallback } from 'react';
import { Card, 
  CardContent, 
  Typography, 
  Divider, 
  Button, 
  CardActions, 
  Paper, 
  Grid, 
  FormControl, 
  FormLabel, 
  RadioGroup, 
  FormControlLabel, 
  Radio, 
  Fab 
} from '@mui/material';
import { Box } from '@mui/system';
import { getIssue, } from 'services/issues';
import { useSnackbar } from 'notistack';
import FullScreenModal from 'components/FullScreenModal';
import CreateUpdateIssue from './CreateIssue';
import AddIcon from '@mui/icons-material/Add';

const Issues = () => {

  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const [issues, setIssues] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editIssueId, setEditIssueId] = useState(null);
  const [issueStatus, setIssueStatus] = useState("");

  useEffect(() => {
    getIssues();
  }, []);

  function getIssues(status='all') {
    getIssue({ status: status || 'all' }).then(({response, error}) => {
      if(error){
        setIssues([]);
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setIssues([...(response || [])]);
      }
    });
  };

  function onFilterValueChange(e) {
    const status = e.target.value;
    setIssueStatus(status);
    getIssues(status);
  }

  function showIssueDetails(event = null, issueId = null) {
    if(event){
      event.preventDefault();
    }
    setOpenModal(true);
    setEditIssueId(issueId);
  }

  const onModalClose = useCallback(
    () => {
      setOpenModal(false);
    },
    [setOpenModal],
  )

 
  const onSuccess = useCallback(
    () => {
      getIssues(issueStatus);
      setOpenModal(false);
    },
    [setOpenModal, issueStatus],
  )

  return (
    <Paper sx={{ minWidth: 275, py: 2, px: 3, minHeight: "calc(100vh - 100px )"}}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{mb: 1}}>
        Issues
      </Typography>
      <Divider />
      <Box noValidate  sx={{ mt: 2, pb: 10}} >
        <Grid container spacing={2} sx={{ m: 0, my: 3, p: 2,  backgroundColor: "#efefef"}}>
          <Grid item xs={6} >
            <FormControl component="fieldset">
              <FormLabel component="legend"><strong>Status</strong></FormLabel>
              <RadioGroup row aria-label="gender" name="row-radio-buttons-group" defaultValue="" onChange={onFilterValueChange}>
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value="closed" control={<Radio />} label="Resolved" />
                <FormControlLabel value="open" control={<Radio />} label="Unresolved" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* <Grid item xs={6} sx={{ borderLeft: "4px solid #fff", px: 2 }}>
            <TextField id="standard-basic" label="Search" variant="standard" sx={{width: '100%'}} /> 
          </Grid> */}
        </Grid>
        <Grid container spacing={3}>
          {issues.map(({id, title, type, createdOn, status}) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
              <Card sx={{ minWidth: 275 }} >
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {createdOn}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {title}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {type}
                  </Typography>
                </CardContent>
                {/* <Divider /> */}
                <CardActions>
                  <Button size="small" sx={{ fontSize: 12, textTransform: 'none', }} onClick={(e) => showIssueDetails(e, id)}>
                    {status === 'closed' ? 'View' : 'Update'}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <FullScreenModal open={openModal} onModalClose={onModalClose} title={ editIssueId ? 'View Issue/Update Issue' : 'Create Issue'}>
          <CreateUpdateIssue issueId={editIssueId} onSuccess={onSuccess} />
        </FullScreenModal>
        <Fab variant="extended" color="primary" aria-label="Create Issue" sx={{ position: 'absolute', bottom: 48,right: 48, textTransform: 'inherit'}} onClick={showIssueDetails}>
          <AddIcon /> Create Issue
        </Fab>
      </Box>
    </Paper>
  );
}
 
export default Issues;
