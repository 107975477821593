// import { fileUploader } from 'core/services/requestHandler';   
import { requestHandler } from './httpRequest';

const getProfileInfo = async (params = {}, config = {}) => {
  return requestHandler.GET({url: '/user', params});
};

const updateProfile = async (body = {}, config = {}) => {
  return requestHandler.PUT({url: '/user', body});
};

const changePassword = async (params = {}, config = {}) => {
  return requestHandler.PUT({url: '/user/changePassword', params});
};

const getUploadedDocuments = async (params = {}, config = {}) => {
  return requestHandler.GET({url: '/user/documentStatus', params});
};


export { getProfileInfo, updateProfile, changePassword, getUploadedDocuments };
