import React, { useState, useEffect } from 'react'
import { AppBar, DialogContent, Dialog, Slide, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FullScreenModal = (props) => {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.onModalClose();
  }

  useEffect(() => {
    if(props.open !== open){
      setOpen(props.open);
    }
  }, [props.open, setOpen, open]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {props.children}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
  );
}
 
export default React.memo(FullScreenModal);
