import React, {useContext, useEffect, useState} from 'react';
import { Grid, Paper } from '@mui/material';
// import { useForm } from "react-hook-form";
import { getUploadedDocuments } from 'core/services/profile';
import { useSnackbar } from 'notistack';
import DocumentCard from './DocumentCard';
import FileUploads from 'components/file-uploads';
import { AuthContext } from 'core/context';

// const defaultFormValues = {
//   old_password: '',
//   new_password:'',
//   confirmNewPassword: ''
// }
const Documents = () => {
  /** Hooks */
  const [documents, setDocuments] = useState({});
  const [showUploadModal, setShowUploadModal] = useState({ isOpen: false, type: null});
 const { inmateId } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  // const { handleSubmit, control, formState: { errors }, reset, watch } = useForm({
  //   mode: 'onTouched',
  //   defaultValues: {...defaultFormValues}
  // });

  useEffect(() => {
    loadUploadedDocuments()
  }, [])
  
  const loadUploadedDocuments = () => {
    getUploadedDocuments().then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message), {variant: 'success'});
      }else{
        const addressProof = response?.addressProofUploaded ? `/rest/user/${inmateId}/document/ADDRESS_PROOF` : null
        const identityProof = response?.idProofUploaded ? `/rest/user/${inmateId}/document/ID_PROOF` : null
        setDocuments({addressProof, identityProof});
      }
    })
  }

  const onUploadBtnClick = (e, type) => {
    setShowUploadModal({ isOpen: true, type});
  }

  const modalClosed = () => {
    setShowUploadModal({ isOpen: false, type: null});
  }

  const onFileUploadSuccess = () => {
    setDocuments({})
    loadUploadedDocuments();
  }

  return ( 
    <Paper  sx={{mt: 1,boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'}}>
      <Grid
        container
        // direction="row"
        justifyContent="left"
        alignItems="center"
        columnSpacing={2} rowSpacing={2}
        sx={{  p:4,  }} 
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
          <DocumentCard title="Address Proof" type={'ADDRESS_PROOF'} onUploadBtnClick={onUploadBtnClick} fileUrl={documents?.addressProof} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}  >
          <DocumentCard  title="Identity Proof" type={'ID_PROOF'} onUploadBtnClick={onUploadBtnClick} fileUrl={documents?.identityProof} />
        </Grid>
      </Grid>
      <FileUploads showUploads={showUploadModal.isOpen} modalTitle={`Upload ${showUploadModal.type === 'ADDRESS_PROOF' ? "Address Proof" : "Identity Proof"}`} modalClosed={modalClosed} type={showUploadModal.type} onFileUploadSuccess={onFileUploadSuccess} />
    </Paper>
  );
}
 
export default Documents;
