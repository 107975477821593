import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Header from 'core/components/header';

import pgmTheme from './theme';
import Navbar from 'core/components/navbar';
import Loader from 'core/components/Loader';

const DefaultLayout = ({ children, navbar=true }) => {
  // const appContext = useContext(AppContext);
  // console.log('default layout', appContext);
  // console.log('loading', loading);
  return (
    <ThemeProvider theme={pgmTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Loader />
        <Header hideNavIcon={!navbar}/>
        {navbar && <Navbar />}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            p: 2,
          }}
        >
          <Toolbar />
          {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
              {children}
          {/* </Container> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
 
export default React.memo(DefaultLayout);
