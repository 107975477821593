import React, { useCallback, useState, useEffect } from 'react';
import ScrollDialog from 'components/ScrollDialog';
import UploadFile from './UploadFile';


function FileUploads({modalTitle = 'Upload Files', showUploads = false, file, modalClosed, type=null, acceptFileType, onFileUploadSuccess}) {

  const [openModal, setOpenModal] = useState(showUploads || false);
  
  const onModalClose = useCallback(
    () => {
      closeModal();
      modalClosed();
    },
    [],
  );

  const closeModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    setOpenModal(showUploads);
  }, [showUploads]);

  const onSuccess = () => {
    closeModal();
    modalClosed();
    if(onFileUploadSuccess){
      onFileUploadSuccess();
    }
  }

  const onFailure = () => {
    closeModal();
    modalClosed();
  }

  return (
    <ScrollDialog onModalClose={onModalClose} open={openModal} title={modalTitle}>
      <UploadFile file={file} onSuccess={onSuccess} onFailure={onFailure} type={type} acceptFileType={acceptFileType} />
    </ScrollDialog>
  );
}

export default React.memo(FileUploads);
