import React from 'react';
import { Route, Routes } from "react-router-dom";
import routes from './routes';
import AuthenticatedRoute from './authenticatedRoute';
import NotFound from "core/pages/404/NotFound";
import { LayoutWithHeader } from 'core/layouts';

const AppRouter = () => {
  return (
    // <Router basename={process.env.REACT_APP_BASENAME || ""} >
      <Routes>
        {routes.map((route, index) => {
          return (
            route?.enableAuth ? ( 
              <Route
              key={index}
              path={route.path}
              
              element={
                <AuthenticatedRoute rolesAssigned={route?.roles}>
                  <route.layout><route.component /></route.layout>
                </AuthenticatedRoute>
              }
            />
            ) : (
              <Route
                key={index}
                path={route.path}
                element={<route.layout><route.component /></route.layout>}
              />
            )
          );
        })}
        <Route
          path="*"
          element={<LayoutWithHeader><NotFound/></LayoutWithHeader>}
        />
      </Routes>
    // </Router>
  );
}
 
export default AppRouter;
