import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';

import pgmTheme from './theme';

const PlainLayout = ({ children }) => {
  return (
    <ThemeProvider theme={pgmTheme}>
      <Box sx={{ display: 'flex' }}>
        {children}
      </Box>
    </ThemeProvider>
  );
}
 
export default PlainLayout;
