import React, { useState, useEffect } from 'react'
import { getInmatePaymentHistory } from 'services/payRent';
import { useSnackbar } from 'notistack';
import { Grid, Typography } from '@mui/material';

const PaymentHostory = () => {

  const [paymentData, setPaymentData] = useState([]);  
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
   getPaymentHistoryData();
  }, []);

  function getPaymentHistoryData() {
    getInmatePaymentHistory().then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setPaymentData([...( response || [])]);
      }
    });
  }

  return (
    <Grid container spacing={0.5}>
      {paymentData?.length > 0? (
        paymentData.map((ele, i) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={i}>
            <Typography variant="subtitle1" component="p">
              {ele}
            </Typography>
          </Grid>
        ))
      ) : 'No Data Found'}
      
    </Grid>
  );
}
 
export default PaymentHostory;
