import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, Typography, Divider, Button, CardActions, Paper, Grid, Tooltip, Link } from '@mui/material';
import { Box } from '@mui/system';
import { fileDownloader, getRentReceipts, SendRentReceiptToMail } from 'services/rentReceipts';
import { useSnackbar } from 'notistack';
import PreviewIcon from '@mui/icons-material/Preview';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ScrollDialog from 'components/ScrollDialog';
import RentReceiptDetails from './RentReceiptDetails';

const RentReceipts = () => {

  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const [paymentId, setPaymentId] = useState(null);
  const [rentReceipts, setRentReceipts] = useState([]);
  const [openRentReceiptModal, setOpenRentReceiptModal] = useState(false);

  useEffect(() => {
    getPaidRentReceipts();
  }, []);

  function getPaidRentReceipts() {
    getRentReceipts().then(({response, error}) => {
      if(error){
        setRentReceipts([]);
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setRentReceipts([...(response || [])]);
      }
    });
  }

  const MailRentReceipt = (payment_id) => {
    SendRentReceiptToMail(payment_id).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      }else{
        enqueueSnackbar('Receipt has been sent to registered email id', {variant:  'success'});
      }
    });
  }

  const onModalClose = useCallback(
    () => {
      setPaymentId(null);
      setOpenRentReceiptModal(false);
    },
    [],
  );

  const showRentReceiptDetails = (event, paymentId) => {
    setPaymentId(paymentId);
    setOpenRentReceiptModal(true);
  }

  function downloadRentReceipt(paymentId) {
    fileDownloader(paymentId).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      }else{
        const a = document.createElement('a');
        const blobObj = new Blob([response], { type: 'octet/stream' });
        const url = window.URL.createObjectURL(blobObj);
        a.href = url;
        a.download = `receipt-${paymentId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  }

  return (
    <Paper sx={{ minWidth: 275, py: 2, px: 3, minHeight: "calc(100vh - 100px )" }}>
      {/* <CardContent> */}
        <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{mb: 1}}>
          Payment Receipts
        </Typography>
        <Divider />
        <Box noValidate  sx={{ mt: 2 }} >
          <Grid container spacing={3}>
            {rentReceipts.map(({paymentId, amountPaid, month, pdf, paymentType}) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={paymentId}>
                {/* <Item> */}
                  <Card sx={{ minWidth: 275 }} >
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {month}
                      </Typography>
                      <Typography variant="h5" component="div">
                        Rs.{amountPaid}/-
                      </Typography>
                      <small>Paid towards {paymentType}</small>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Button color='secondary' size="small" sx={{ fontSize: 12, flex: 1, textTransform: 'none', }} onClick={(e) => showRentReceiptDetails(e, paymentId)}>
                      <Tooltip title="View Receipt Details" arrow placement="top" enterDelay={300}><PreviewIcon /></Tooltip>
                      </Button>
                      <Button color='secondary' size="small" sx={{ fontSize: 12, flex: 1, textTransform: 'none', }} onClick={() => MailRentReceipt(paymentId)}>
                        <Tooltip title="Send Rent Receipt to Mail" arrow placement="top" enterDelay={300}><ForwardToInboxIcon /></Tooltip>
                      </Button>
                      <Button color='secondary' size="small" sx={{ fontSize: 12, flex: 1, textTransform: 'none', }}>
                        <Tooltip title="Download Rent Receipt" arrow placement="top" enterDelay={300}>
                          <Link href="#" onClick={() => downloadRentReceipt(paymentId)} color="inherit"><CloudDownloadIcon /></Link>
                        </Tooltip>
                      </Button>
                    </CardActions>
                  </Card>
                {/* </Item> */}
              </Grid>
            ))}
          </Grid>
        </Box>
      {/* </CardContent> */}
      <ScrollDialog onModalClose={onModalClose} open={openRentReceiptModal} title="Receipt Details">
        <RentReceiptDetails paymentId={paymentId} />
      </ScrollDialog>
    </Paper>
  );
}
 
export default RentReceipts;
