import { Navigate } from "react-router-dom";

import { DefaultLayout } from "core/layouts";
import Dashboard from "pages/Dashboard";
import PayRent from "pages/rent/PayRent";
import RentReceipts from "pages/rent-receipts";
import Issues from 'pages/issues';
import FoodRegister from "pages/food-register";
import CheckoutNotice from "pages/checkout-notice";

const AppRoutes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Navigate to="/overview/" replace={true} />
  },
  {
    path: "/overview",
    layout: DefaultLayout,
    component: Dashboard,
    enableAuth: true,
  },
  {
    path: "/rent/",
    layout: DefaultLayout,
    component: PayRent,
    enableAuth: true,
  },
  {
    path: "/rent-receipts/",
    layout: DefaultLayout,
    component: RentReceipts,
    enableAuth: true,
  },
  {
    path: "/food-register/",
    layout: DefaultLayout,
    component: FoodRegister,
    enableAuth: true,
  },
  {
    path: "/issues/",
    layout: DefaultLayout,
    component: Issues,
    enableAuth: true,
  },
  {
    path: "/checkout-notice/",
    layout: DefaultLayout,
    component: CheckoutNotice,
    enableAuth: true,
  },
  // {
  //   path: "/employees/search/",
  //   layout: DefaultLayout,
  //   component: SearchEmployee,
  //   enableAuth: true,
  //   roles: ['admin', 'manager']
  // },
  // {
  //   path: "/employees/edit/:userId",
  //   layout: DefaultLayout,
  //   component: AddEmployee,
  //   enableAuth: true,
  //   roles: ['admin', 'manager']
  // },
  // {
  //   path: "/user/add/",
  //   layout: DefaultLayout,
  //   component: AddUser,
  //   enableAuth: true,
  //   roles: ['admin', 'manager', 'employee']
  // },
  // {
  //   path: "/user/search/",
  //   layout: DefaultLayout,
  //   component: SearchUser,
  //   enableAuth: true,
  //   roles: ['admin', 'manager', 'employee']
  // },
  // {
  //   path: "/user/edit/:userId",
  //   layout: DefaultLayout,
  //   component: AddUser,
  //   enableAuth: true,
  //   roles: ['admin', 'manager', 'employee']
  // },
  // {
  //   path: "/pg/search/",
  //   layout: DefaultLayout,
  //   component: SearchPG,
  //   enableAuth: true,
  //   roles: ['admin', 'manager', 'employee']
  // },
  // {
  //   path: "/pg/add/",
  //   layout: DefaultLayout,
  //   component: CreatePG,
  //   enableAuth: true,
  //   roles: ['admin', 'manager', 'employee']
  // },
  // {
  //   path: "/pg/edit/:pgId",
  //   layout: DefaultLayout,
  //   component: AddPG,
  //   enableAuth: true,
  //   roles: ['admin', 'manager', 'employee']
  // },
  // {
  //   path: "/pg/license/renew/:pgId/:userId",
  //   layout: DefaultLayout,
  //   component: UpdatePgLicense,
  //   enableAuth: true,
  //   roles: ['admin', 'manager', 'employee']
  // },
];
export default AppRoutes;
