import React, { useState, useEffect } from 'react'
import { Box, TextField, Button, Grid } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { format, parse } from 'date-fns';
// import Rating from '@mui/material/Rating';
import { sendCheckoutNotice, updateCheckoutNotice } from 'services/checkoutNotice';

const CreateNotice = ({ noticeData = null, onNoticeSubmit}) => {
  const defaultFormFields = {
    checkoutDate: new Date(),
    reason: '',
    // rating: 0,
    // feedback: '',
  };

  const [dateValue, setDateValue] = useState(new Date());
  // const [pgRating, setPgRating] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState: { errors }, reset } = useForm({
    mode: 'onTouched',
    defaultValues: {...defaultFormFields}
  });

  useEffect(() => {
    if(noticeData){
      reset({...noticeData, checkoutDate: parse(noticeData.checkoutDate, 'dd-MM-yyyy', new Date())});
      setDateValue(parse(noticeData.checkoutDate, 'dd-MM-yyyy', new Date()));
      // setPgRating(parseInt(noticeData.rating))
    }
  }, [noticeData])
  

  function createCheckoutNotice(data) {
    sendCheckoutNotice({...data, checkoutDate: format(dateValue, 'dd-MM-yyyy')}).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message), {variant: 'error'});
      } else {
        enqueueSnackbar((response?.message || 'Notice created'), {variant: 'success'});
        reset({...defaultFormFields});
        onNoticeSubmit();
      }
    });
  }

  function updateNotice(data) {
    updateCheckoutNotice({...data, checkoutDate: format(dateValue, 'dd-MM-yyyy')}).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message), {variant: 'error'});
      } else {
        enqueueSnackbar((response?.message || 'Notice created'), {variant: 'success'});
        reset({...defaultFormFields});
        onNoticeSubmit();
      }
    });
  }

  const onSubmit = (data) => {
    console.log('data', data)
    if(data?.id){
      updateNotice(data);
    }else{
      createCheckoutNotice(data)
    }
  }

  return (
    <Box noValidate component="form"  onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }} >
      <Grid container columnSpacing={2} rowSpacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}  >
          <Grid container columnSpacing={2} rowSpacing={2}>

            <Grid item xs={12} sm={12} md={7} lg={7} sx={{mt: 2.5}}>
              <Controller
                control={control}
                name="checkoutDate"
                id="date"
                rules={{ required: "Select vacating date" }}
                render={({field}) => ( 
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DatePicker
                      // {...field}
                      label="Vacating Date"
                      inputFormat="dd/MM/yyyy"
                      clearable={false}
                      minDate={new Date()}
                      value={dateValue}
                      onChange={(newValue) => setDateValue(newValue)}
                      required
                      renderInput={(params) => <TextField  required {...field} {...params} size="small" sx={{width: '100%'}} />}
                      slotProps={{textField: {fullWidth: true}}}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7} >
              <Controller
                control={control}
                name="reason"
                id="reason"
                
                rules={{ required: "Enter reason" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Reason"
                    required
                    error={errors.reason?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={6}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} lg={12} >
              <Controller
                control={control}
                name="feedback"
                id="feedback"
                rules={{ required: "Enter feedback" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Feedback"
                    // required
                    // error={errors.feedback?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={6}
                  />
                )}
              />
            </Grid> */}
  
            {/* <Grid item xs={12} sm={12} md={12} lg={12} sx={{mt: 2.5}}>
              <Typography component="legend" sx={{mb:1}}>Rate your PG</Typography>
              <Controller
                control={control}
                name="rating"
                id="rating"
                rules={{ required: "Rate your PG" }}
                render={({field}) => (
                  <Rating
                    {...field}
                    size="large"
                    required
                    name="simple-controlled"
                    value={pgRating}
                    onChange={(event, newValue) => {
                      setPgRating(newValue);
                    }}
                  />
                )}
              />
            </Grid> */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, textTransform: 'inherit' }}
                startIcon={<AddIcon />}
                color="primary"
              >
                {noticeData ? 'Update' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
 
export default CreateNotice;
