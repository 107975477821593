import { DefaultLayout, PlainLayout } from "core/layouts";
import Login from "core/pages/Login";
import Register from "core/pages/Register";
import PasswordReset from "core/pages/PasswordRest";
import Profile from "core/pages/Profile/Profile";
import UnAuthorized from "core/pages/UnAuthorized";
import AppRoutes from "routes";

const Routes = [
  {
    path: "/signin/",
    layout: PlainLayout,
    component: Login,
  },
  {
    path: "/register/",
    layout: PlainLayout,
    component: Register,
  },
  {
    path: "/profile/",
    layout: DefaultLayout,
    component: Profile,
    enableAuth: true,
  },
  {
    path: "/password-reset/",
    layout: PlainLayout,
    component: PasswordReset,
  },
  {
    path: "/unauthorized/",
    layout: DefaultLayout,
    component: UnAuthorized,
    enableAuth: true,
  },
  ...AppRoutes
];
export default Routes;
