import React, { useContext, useEffect, useState } from 'react';  
import { Avatar, Button, CssBaseline, TextField, Link, Box, Grid, Typography, Paper, CircularProgress, Container, Divider } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { userSignin } from 'core/services/auth';
import { useNavigate, Link as RouteLink } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { AuthContext } from 'core/context';
import LoginIcon from '@mui/icons-material/Login';
import Footer from 'core/components/footer';


export default function SignInSide() {
  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [isSiningIn, setSiningIn] = useState(false)
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      mobile: '',
      password:''
    }
  });
  
  useEffect(() => {
    if(authContext?.isUserLoggedIn){
      navigate('/overview', {replace: true})
    }
  }, [authContext, navigate]);

  const onSubmit = (data) => {
    setSiningIn(true);
    userSignin(data).then(({ response, error }) => {
      setSiningIn(false);

      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
        return;
      }

      // authContext.updateAuthContext({
      //   isUserLoggedIn: true,
      //   authToken: response?.data?.authToken,
      //   inmateId: response?.data?.inmate_details?.inmate_id,
      //   data: {
      //     name: `${response?.data?.inmate_details?.inmate_name}`,
      //     pgId: response?.data?.inmate_details?.pg_id,
      //     pgName: response?.data?.inmate_details?.pg_name,
      //   }
      // });
      navigate('/overview');
      
    });
  };

  return (
      <Grid container component="main"
        sx={{ 
          height: '100vh',
          backgroundImage: `url(${process.env.REACT_APP_PATH}/assets/images/login-banner.jpg)`,
          backgroundPosition: 'center', 
          backgroundSize: 'cover',
        }}
      >
        <CssBaseline />
        {/* <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${process.env.REACT_APP_PATH}/assets/images/login-banner.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <Box
            sx={{
              // py: 8,
              // px: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height:'100%',
              justifyContent: 'center'

            }}
          >
            <Typography component="h2" variant="h3" color="white" sx={{ padding: 1, borderBottom: '8px solid #e40047'}}>
              PG Manager Reseller Console
            </Typography>
          </Box>
        </Grid>  */}
        <Container component="div" minwidth="xs" sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', minWidth: 420}}>
          <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} borderRadius={4} sx={{ minWidth: 420}}>
            <Box
              sx={{
                // my: 8,
                // mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 4,
              }}
              >
              <Avatar
                variant='circular'
                sx={{ m: 2, bgcolor: 'primary.main', width: 80, height: 80 }}
                alt="PG Manager Logo" src={`${process.env.REACT_APP_PATH}/assets/images/login-logo.png`}
                className='login-logo'
                />
              <Typography component="h1" variant="h5">
                Sigin to your account
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                <Controller
                  control={control}
                  name="mobile"
                  id="mobile"
                  autoComplete="mobile"
                  rules={{ required: "Enter your mobile",
                    pattern: {
                      value: /^[0-9]{10}$/i,
                      message: "Enter a valid 10 digit mobile number",
                    },
                  }}
                  render={({field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      autoFocus
                      label="Mobile Number"
                      required
                      error={errors.mobile}
                      helperText={errors.mobile?.message}
                      />
                      )}
                    />
                <Controller
                  control={control}
                  name="password"
                  id="password"
                  rules={{ required: "Please Enter Password" }}
                  render={({field}) => (
                    <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    label="Password"
                    autoComplete="current-password"
                    type="password"
                    required
                    error={errors.password?.type === 'required'}
                    helperText={errors.password?.message}
                    />
                    )}
                    />
                <Grid container>
                  <Grid item xs>
                    <Link component={RouteLink} to="/password-reset/">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" >
                  <Button
                    disabled={isSiningIn}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, px: 3, textTransform: 'inherit' }}
                    endIcon={isSiningIn ? <CircularProgress size={20} /> : <LoginIcon /> }
                    size="large"
                    >
                    {isSiningIn ? 'Signing In': 'Sign In'}
                  </Button>
                </Grid>
                <Grid container justifyContent="center" sx={{mt: 2}} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider>
                    <Typography variant='body1'  >
                      New here?
                    </Typography>
                  </Divider>
                <Typography variant='body1' display="block" sx={{my: 3, textAlign: 'center'}}>
                  <Button variant='outlined'  component={RouteLink} to={'/register/'} >Create Account</Button>
                </Typography>
                </Grid>
              </Grid>
                <Footer sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Container>
      </Grid>
  );
}
