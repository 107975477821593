// import { dataFetcher } from 'core/services/requestHandler';
import { requestHandler } from './httpRequest';

const userSignin = async (body={}) => {
  return requestHandler.POST({url: '/authenticate', body});
};

export async function getAppStatus(){
  return requestHandler.GET({url: '/user/landing'});
};

export function resetPassword(params) {
  return requestHandler.PUT({url: '/user/resetPassword', params});
};

const DeleteUserInfoInBrowser = () => {
  window.sessionStorage.removeItem('rpgmUserInfo');
  window.sessionStorage.removeItem('pgc$token');
}

export function sendPasswordResetOTP(mobile) {
  return requestHandler.GET({url: `/user/${mobile}/sendPasswordResetOtp`});
}


const registerUser = ({body, otp}) => {
  return requestHandler.POST({url: `/user/register/${otp}`, body});
}

const sendSignUpOTP = ({data = {}}) => {
  const { mobile } = data;
  return requestHandler.GET({url:`/user/${mobile}/sendOtp`});
}


export { userSignin, DeleteUserInfoInBrowser, registerUser, sendSignUpOTP };
