
import React, {useState, useEffect, useContext} from 'react';
import { Avatar, Grid, Paper, FormControlLabel, Switch, Typography, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { useFileUpload } from 'core/hooks/useFileUpload';
import { useSnackbar } from 'notistack';
// import { useForm, Controller } from "react-hook-form";
// import { uploadProfilePhoto } from 'core/services/profile';
import FileUploads from 'components/file-uploads';
import { getUploadedDocuments } from 'core/services/profile';
import { AuthContext } from 'core/context';

const AccountLogo = ({accountDetails}) => {

  // const defaultFormValues = {image: ''};
  const { active = 'N', name, email = '', mobile = '' } = accountDetails;
  const [profileImage, setProfileImage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  // const { handleSubmit, control, formState: { errors } } = useForm({
  //   defaultValues: {...defaultFormValues}
  // });
  // const [fileInput, setFileInput] = useState();
  const [UploadFile, setUploadFile] = useState(false);
  const { inmateId, data: { pgName } } = useContext(AuthContext);

  // const [uploadPhoto] = useFileUpload({
	// 	onStarting: (file) => {
  //     setFileInput(file);
	// 		var reader = new FileReader();
	// 		reader.readAsDataURL(file)
	// 		reader.onloadend = () => setProfileImage(reader.result)
  //     // onSubmit({image: file});
	// 	}
  // });


  function loadUploadedDocuments(){
    getUploadedDocuments().then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message), {variant: 'success'});
      }else{
        const photoUploaded = response?.photoUploaded ? `/rest/user/${inmateId}/document/PHOTO` : null
        setProfileImage(photoUploaded);
      }
    })
  }

  useEffect(() => {
    loadUploadedDocuments();
  }, []);


  function onFileUploadSuccess() {
    setProfileImage(null);
    loadUploadedDocuments();
  }

  const modalClosed = () => {
    setUploadFile(false);
  }

  return (
    <Paper  sx={{py: 6, px: 3, boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'}}>
      <Grid
        container
        direction="row" 
        justifyContent="center"
        alignItems="center"
      >
        { profileImage ? (
          <Avatar variant="square" src={profileImage} sx={{ width: 200, height: 200 }} /> 
        ) : <Avatar variant="square" sx={{ width: 200, height: 200 }} >
          <AccountCircleIcon sx={{ fontSize: 150 }} />
        </Avatar>}
        {pgName ? null : (
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Button type="button" variant="contained" color="success" size="small" sx={{m: 1}} onClick={() => setUploadFile(true)}>
            Upload
          </Button>
          <FileUploads onFileUploadSuccess={onFileUploadSuccess} showUploads={UploadFile} modalTitle="Upload Profile Photo" modalClosed={modalClosed} type={'PHOTO'} accept='.jpg, .jpeg' />
        </Grid>)}
      </Grid>
      {/* <Grid container direction="row" justifyContent="center" alignItems="center" sx={{my: 1, p: 1}}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{textAlign: "center"}} >
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
            <Button variant="outlined" color="secondary" size="medium" sx={{m: 1}}>

                <Controller
                  control={control}
                  name="image"
                  id="image"
                  // rules={{ required: "Enter your mobile",
                  //   pattern: {
                  //     value: /^[0-9]{10}$/i,
                  //     message: "Enter a valid 10 digit mobile number",
                  //   },
                  // }}
                  render={({field }) => (
                    // <label htmlFor="image" >
                      <>
                      <TextField
                        {...field}
                        margin="normal"
                        type="file"
                        fullWidth
                        // sx={{display: 'none'}}
                        // autoFocus
                        // label="Mobile Number"
                        // required
                        // error={errors.mobile}
                        // helperText={errors.mobile?.message}
                        onChange={(e) => {uploadPhoto(e); return true;}}
                        />
                      </>
                      // </label>

                      // <input
                      //   {...field}
                      //   style={{ display: "none" }}
                      //   // id="profile-photo"
                      //   // name="profile-photo"
                      //   type="file"
                      //   onChange={uploadPhoto}
                      // />
                  )}
                />
            <InputLabel htmlFor="image" >Email address</InputLabel>

            </Button>
            <Button type="submit" variant="contained" color="success" size="medium" sx={{m: 1}}>
              Upload
            </Button>
          </Box>
        </Grid>
      </Grid> */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography variant="h6" component="p" sx={{mt: 3}}>
            {name}
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography  component="p">
            {mobile}
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography variant="caption"  sx={{mt: 1}}>
              {email}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mt: 4}}
      >
        <FormControlLabel disabled control={<Switch checked={active === 'Y'} color="success" />} label="Active" labelPlacement="start" />
      </Grid>
    </Paper>
  );
}
 
export default AccountLogo;
