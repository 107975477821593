import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import { Grid, Box, Button, TextField, Typography } from '@mui/material';
import { uploadFiles } from 'services/commonServices';
import { useFileUpload } from 'core/hooks/useFileUpload';

function UploadFile({file, type = null, onSuccess, onFailure, acceptFileType='image/*'}) {
  const [fileInput, setFileInput] = useState();

  const defaultFormValues ={
    type: type,
    image: '',
  }

  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {...defaultFormValues}
  });

  const [onFileUpload] = useFileUpload({
		onStarting: (file) => {
      setFileInput(file);
			var reader = new FileReader();
			reader.readAsDataURL(file)
			reader.onloadend = () => {}
      // onSubmit({image: file});
		}
  });

  const onSubmit = (data) => {
    const postData = {
      file: fileInput,
      fileName: fileInput.name,
      documentType: data.type,
    };
    uploadFiles({...postData}).then(({response, error}) => {
      if(error){
        onFailure()
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      }else{
        onSuccess();
        enqueueSnackbar((response?.message || 'File uploaded sucessfully'), {variant: 'success'});
      }
    });
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{my: 1, p: 1}}>

        {type ? <Typography variant="subtitle1" component="div">Select File</Typography> : (
          // <Grid item xs={12} sm={12} md={12} lg={12} >
          //   <FormControl error={!!errors?.type}>
          //     <FormLabel id="fileupload-row-radio-buttons-group-label">Type</FormLabel>
          //     <Controller
          //         control={control}
          //         name="type"
          //         id="type"
          //         required
          //         rules={{ required: "Select type" }}
          //         render={({field}) => (
          //         <RadioGroup
          //           {...field}
          //           row
          //           aria-labelledby="fileupload-row-radio-buttons-group-label"
          //           name="rtype"
          //         >
          //           <FormControlLabel value="1" control={<Radio />} label="Address Proof" />
          //           <FormControlLabel value="2" control={<Radio />} label="Identity Proof" />
          //           <FormControlLabel value="3" control={<Radio />} label="Profile Photo" />
          //         </RadioGroup>
          //       )}
          //     />
          //   </FormControl>
          // </Grid>
          null
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{textAlign: "center"}} >
          <Controller
            control={control}
            name="image"
            id="image"
            rules={{ required: "Select file to upload"}}
            render={({field }) => (
              <TextField
                {...field}
                error={!!errors.image}
                margin="normal"
                type="file"
                fullWidth
                className='error'
                // sx={{display: 'none'}}
                // autoFocus
                // label="Mobile Number"
                // required
                // error={errors.mobile}
                inputProps={{
                  accept: acceptFileType
                }}
                helperText={errors.image?.message}
                onChange={(e) => {field.onChange(e); onFileUpload(e);}}
              />
            )}   
          />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Button type="submit" variant="contained" color="success" size="small" sx={{m: 1}}>
            Upload
          </Button>
        </Grid>

      </Grid>
    </Box>
  );
}

export default React.memo(UploadFile);
