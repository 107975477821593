import axios from 'axios';
import qs from 'qs';
import authService from 'core/services/authService';
import { DeleteUserInfoInBrowser } from 'core/services/auth';

class RequestHandler {
  #axiosInstance;
  #authExecudedRequestsArr

  constructor(requestsArr) {
    this.#authExecudedRequestsArr = requestsArr;
    this.createAxiosInstance();
  }

  // Axois config defaults 
  getInstanceConfig(requestConfig = {}){
  
    const defaultConfig = {
      baseURL: `${process.env.REACT_APP_API_ORIGIN ? process.env.REACT_APP_API_ORIGIN : ''}${process.env.REACT_APP_API_PATH}/rest`
    }
    return {
      ...defaultConfig,
      ...requestConfig
    };
  }

  // Create an instance using the config defaults provided by the library
  createAxiosInstance(){
    // Get config defaults when creating the instance
    const instanceConfig = this.getInstanceConfig();
    
    this.#axiosInstance = axios.create(instanceConfig);

    // Response Interceptor
    this.#axiosInstance.interceptors.response.use(
      function (res) {
        if(res.status === 200){
          const url = res?.config?.url
          if(url === '/authenticate' && res.headers?.authorization){
            authService.setAuthToken(res.headers.authorization.replace('Bearer ', ''));
          }
        }
        
        return res;
      },
      function (error) {
        if(error?.response?.request?.responseURL?.includes('/user/landing') && error?.response?.status !== 200) {
          window.location.href='/signin';
        // Do something with response error
        }else if(error.response.status === 406) {
          DeleteUserInfoInBrowser();
          window.location.reload();
        }else if(error.response.status === 401){
          window.location.href='/signin';
        }
        // Trow errr again (may be need for some other catch)
        return Promise.reject(error.response.data);
    }
    );
  }

  isPathExcludedFromAuthentication(url) {
    let isExcluded = false;

    for (let i = 0; i < this.#authExecudedRequestsArr.length; i++) {
      const excludePath = this.#authExecudedRequestsArr[i];
      if (url.includes(excludePath)) {
        isExcluded = true;
        break; 
      }
    }
    return isExcluded;
  }

  addAccessToken(config={}){
    if(this.isPathExcludedFromAuthentication(config.url)){
      return {...config};
    }

    const authToken = authService.getAuthToken();

    if(authToken && authToken !== 'null'){
      return { ...config, headers: { ...(config.headers || {}),  'Authorization': `Bearer ${authToken}` }  }
    }
    return config;
  }

  async sendRequest({url, method='GET', params={}, body={}, reqConfigs={}}){
    try{

      if(!url) return;

      let config = {
        url,
        method,
        ...reqConfigs
      }

      config = this.addAccessToken(config);

      // assign query params if available
      if(Object.keys(params).length > 0) {
        config.params = { ...params };
        config.paramsSerializer = function (params) {
          return qs.stringify(params, {arrayFormat: 'brackets'})
        };
      }

      // assign body data    
      if(method === 'POST' || method === 'PUT') {
        config.data = body;
      } 
      // Get configs for request
      const requestConfig = this.getInstanceConfig(config);
      const response = await this.#axiosInstance(requestConfig);

      return {
        response: response.data,
        error: undefined,
      };
    }catch(e){
      return {
        response: undefined,
        error: e.message,
      };
    }
  }

  async GET({url, params={}, reqConfigs={}}) {
    return this.sendRequest({url, method: 'GET', params, reqConfigs}); 
  }

  async PUT({url, body={}, params={}, reqConfigs={}}) {
    return this.sendRequest({url, method: 'PUT', params, body, reqConfigs}); 
  }

  async POST({url, body={}, params={}, reqConfigs={}}) {
    return this.sendRequest({url, method: 'POST', params, body, reqConfigs}); 
  }
  
  async DELETE({url, params={}, reqConfigs={}}) {
    return this.sendRequest({url, method: 'DELETE', params, reqConfigs}); 
  }
  
  async FILE({url, params={}, configs={}}) {
    const fileConfigs = { headers: {'accept': "application/octet-stream"}, responseType: "blob" };
    return this.sendRequest({url, method: 'GET', params, reqConfigs: {...fileConfigs, ...configs}}); 
  }
  
  async FILEUPLOAD({url, params={}, body={},configs={}}) {
    const fileConfigs = { headers: {'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)} };
    const formBody = this.getFormData(body);
    return this.sendRequest({url, method: 'POST', params, body: formBody, reqConfigs: {...fileConfigs, ...configs}}); 
  }

  getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }

  getAxiosInstance() { return this.#axiosInstance }
}

const authExecudedRequest=['/authenticate', 'sendPasswordResetOtp'];

const requestHandler = new RequestHandler(authExecudedRequest);
const axiosInstance = requestHandler.getAxiosInstance();

export { requestHandler, axiosInstance };
