import React, { useMemo } from 'react';
import { Card, Box, CardContent, Button, Typography } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
// const noDocUrl = '/images/not-available.jpg';

function DocumentCard({title, type, onUploadBtnClick, fileUrl}) {

  // const isImage = useMemo(() => fileUrl.match(/\.(jpg|jpeg|png|gif)$/), [fileUrl]);

  return (
    <Box sx={{ minWidth: 275, textAlign: 'center' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle2"  sx={{mt: 1, mb: 2}}>
            {title}
          </Typography>
        </CardContent>
        <Box>
          {fileUrl ? (
            <img src={fileUrl} style={{width: '100%'}} alt="File Available" />
          ) : <FilePresentIcon />}
        </Box>
        <Box  sx={{p:2}}>
          <Button variant="contained" size="small" onClick={(e) => onUploadBtnClick(e, type)}>Upload</Button>
        </Box>
      </Card>
    </Box>
  );
}

export default React.memo(DocumentCard);
