import { memo } from "react"
import { ErrorOutline, HighlightOffRounded, TaskAltRounded } from "@mui/icons-material";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { green, grey, orange, red } from "@mui/material/colors";
// import { generatePath, Link as RouteLink } from "react-router-dom";
// import { ROUTES } from "routes/constants";
import { formatCurrency } from "utils/common";

export default memo(function PaymentStatusMessage({txnData, businessId, reset, showNextAction=true}) {
  let status = txnData?.STATUS.replace('TXN_', '').toLowerCase();
  const totalAmount = txnData?.totalAmount || txnData?.TXNAMOUNT || 0;
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} textAlign="center" sx={{border: `1px solid ${grey[200]}`, borderRadius: 1, p: 3}}>
        {status === 'success' ? (
            <>
              <TaskAltRounded sx={{fontSize: 100, color: green[200]}} />
              <Typography variant="h5" fontWeight={500} color={green[800]}>Payment successful!</Typography> 
              <Typography variant="body2" paddingY={2}>Thank you for the payment.</Typography> 

              <Stack justifyContent="flex-start" textAlign="left" marginTop={2}>
                <Typography variant="caption" display="block"  color="text.secondary">Order Id: <strong>{txnData?.ORDERID}</strong></Typography> 
                <Typography variant="caption" display="block" color="text.secondary">Transaction Id: <strong>{txnData?.TXNID}</strong></Typography> 
                <Typography variant="caption" display="block"  color="text.secondary">Amount: <strong>{formatCurrency(totalAmount)}</strong></Typography> 
              </Stack>

              {/* <Stack justifyContent="cente" marginTop={2} sx={{px: 4}} rowGap={2}>
                <Button sx={{color: green[800]}} variant="text" component={Link} href={getLicenseInvoicePath(txnData?.ORDERID)} target="blank" rel="noopener" >Download invoice</Button>
                {businessId ? 
                  <Button size="large" color="success" variant="contained" sx={{p:1}} 
                    component={RouteLink} 
                    to={generatePath(ROUTES.HOME, {businessId})}
                  >
                    Continue
                  </Button>
                : null}
              </Stack> */}
            </>
          ): (status === 'pending' ? (
            <>
              <ErrorOutline sx={{fontSize: 100, color: orange[200]}} />
              <Typography variant="h5" fontWeight={500} color={orange[800]}>Payment pending</Typography>
              <Typography variant="body2" paddingY={2}>Your payment is still pending. For more information, please get in touch with us.</Typography> 
              {txnData?.RESPMSG ? <Typography variant="caption" display="block" color="textSecondary">{txnData?.RESPMSG}</Typography> : null}
              
              <Stack justifyContent="flex-start" textAlign="left" marginTop={2}>
                <Typography variant="caption" display="block"  color="text.secondary">Order Id: <strong>{txnData?.ORDERID}</strong></Typography> 
                <Typography variant="caption" display="block" color="text.secondary">Transaction Id: <strong>{txnData?.TXNID}</strong></Typography> 
                <Typography variant="caption" display="block"  color="text.secondary">Amount: <strong>{formatCurrency(totalAmount)}</strong></Typography> 
              </Stack>

              {/* {showNextAction ? <Stack justifyContent="cente" marginTop={4} sx={{px: 4}} rowGap={2}>
                <Button size="large" color="warning" variant="outlined" sx={{p:1}} 
                  component={RouteLink} 
                  to={generatePath(ROUTES.HOME, {businessId})}
                >
                  Contact us
                </Button>
              </Stack> : null} */}
            </>
          ): 
          <>
            <HighlightOffRounded sx={{fontSize: 100, color: red[200]}} />
            <Typography variant="h5" fontWeight={500} color={red[800]}>Opps, payment failed!</Typography>
            <Typography variant="body2" paddingY={2}>Something went wrong.</Typography> 
            {txnData?.RESPMSG ? <Typography variant="caption" display="block" color="textSecondary">{txnData?.RESPMSG}</Typography> : null}

            <Stack justifyContent="flex-start" textAlign="left" marginTop={3}>
                <Typography variant="caption" display="block"  color="text.secondary">Order Id: <strong>{txnData?.ORDERID}</strong></Typography> 
                <Typography variant="caption" display="block" color="text.secondary">Transaction Id: <strong>{txnData?.TXNID}</strong></Typography> 
                <Typography variant="caption" display="block"  color="text.secondary">Amount: <strong>{formatCurrency(totalAmount)}</strong></Typography> 
              </Stack>

              <Stack justifyContent="cente" marginTop={4} sx={{px: 4}} rowGap={2}>
                <Button size="large" color="error" variant="contained" sx={{p:1}} onClick={reset}>
                  Try again
                </Button>
              </Stack>
          </> 
        )}
       
      </Grid>
    </Grid>
  )
});
