import { requestHandler } from 'core/services/httpRequest';
import { dataFetcher, fileUploader } from 'core/services/requestHandler';   


export const getUserDetailsByMobile = (data = {}, config = {}) => {
  return dataFetcher('user/getUserSuggestionsByMobile', data, {...config});
};

export const uploadFiles = async (body = {}, config = {}) => {
  return requestHandler.FILEUPLOAD({url: '/user/document', body});
};
