import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@mui/material';
import AccountLogo from './AccountLogo';
import AccountInfo from './AccountInfo';
import { getProfileInfo, updateProfile } from 'core/services/profile';
import { useSnackbar } from 'notistack';

const Account = () => {
  /** Declarations */
  const defaultAccountFields = {
    'name': '',
    'mobile': '',
    'email': '',
    'emergencyContactName': '',
    'emergencyContactNumber': '',
    'address': '',
    'workplaceCollege': '',
    'workplaceCollegeAddress': '',
    'reference1': '',
    'reference1Number': '',
    'reference2': '',
    'reference2Number': '',
    'uuid': ''
    // 'active': 'Y',
    // 'checkin': 'Y',
  };
  
  /** Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const [accountDetails, setAccountDetails] = useState(defaultAccountFields);
  // const [profilePhoto, setProfilePhoto] = useState(null);

  useEffect(() => {
    getAccountDetails();
    // loadUploadedDocuments();
  }, []);


  function getAccountDetails() {
    getProfileInfo().then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      }
        setAccountDetails({
          name: response?.inmateDetails?.name || '',
          mobile: response?.mobile || '',
          email: response?.inmateDetails?.email || '',
          emergencyContactName: response?.inmateDetails?.emergencyContactName || '',
          emergencyContactNumber: response?.inmateDetails?.emergencyContactNumber || '',
          address: response?.inmateDetails?.address || '',
          workplaceCollege: response?.inmateDetails?.workplaceCollege || '',
          workplaceCollegeAddress: response?.inmateDetails?.workplaceCollegeAddress || '',
          reference1: response?.inmateDetails?.reference1 || '',
          reference1Number: response?.inmateDetails?.reference1Number || '',
          reference2: response?.inmateDetails?.reference2 || '',
          reference2Number: response?.inmateDetails?.reference2Number || '',
          uuid: response?.uuid
          // active: response?.inmateDetails?.active || 'Y',
          // checkin: response?.inmateDetails?.checkin || 'N',
        }); 

        // if(response?.inmateDetails?.photo){
        //   setProfilePhoto(response?.inmateDetails?.photo);
        // }
      
    });
  }

  const updateProfileInfo = useCallback(
    (data) => {
      delete data['mobile'];
      updateProfile(data).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      }else{
        enqueueSnackbar((response?.message || 'Account details updated successfully'), {variant: 'success'});
      }
      })
    },
    [enqueueSnackbar],
  );

  return (
  <Grid container spacing={3} sx={{pt: 2}}>
    <Grid item xs={12} md={3}  sm={12} lg={4}>
      <AccountLogo accountDetails={accountDetails}  />
    </Grid>
    <Grid item xs={12} sm={12} md={9} lg={8}>
      <AccountInfo accountDetails={accountDetails} updateProfileInfo={updateProfileInfo}/>
    </Grid>
  </Grid>
  );
}
 
export default Account;
