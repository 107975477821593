import { requestHandler } from 'core/services/httpRequest';
// import { dataFetcher } from 'core/services/requestHandler';   

const getFoodData = async () => {
  return requestHandler.GET({url: '/food'});
};

const updateFoodData = async (body = {}) => {
  return requestHandler.POST({url: '/food', body});
};

export { getFoodData, updateFoodData  };
