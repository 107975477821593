import axios from 'axios';
import qs from 'qs';
import authService from 'core/services/authService';
import { DeleteUserInfoInBrowser } from 'core/services/auth';

// Axois config defaults 
const getInstanceConfig = (requestConfig = {}) => {

  const defaultConfig = {
    baseURL: `${process.env.REACT_APP_API_ORIGIN ? process.env.REACT_APP_API_ORIGIN : ''}/rest/api/`
  }
  return {
    ...defaultConfig,
    ...requestConfig
  };
}

// Get config defaults when creating the instance
const instanceConfig = getInstanceConfig();

// Create an instance using the config defaults provided by the library
const axiosInstance = axios.create(instanceConfig);

axiosInstance.interceptors.request.use(
  function (req) {
    return req;
  },
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    // Do something with response error
    if(error.response.status === 406) {
      DeleteUserInfoInBrowser();
      window.location.reload();
    }
    // Trow errr again (may be need for some other catch)
    return Promise.reject(error);
}
);
const dataFetcher = async (url, data = {}, reqConfig = {}) => {
  try{
    const authToken = authService.getAuthToken();
    const config = {
      method: 'GET',
      ...reqConfig
    }
    let queryParams = {};
    const requestName = url.split('/').pop();
    if(requestName){
      queryParams = {...queryParams, CPKP: requestName};
    }
    // const queryStr = config.method === 'GET' ? qs.stringify(data, {
    //   addQueryPrefix: true,
    //   encode: false,
    // }) : '';
    config.url = url;
    if(authToken){
      config.headers = { ...config.headers,  'authToken': authToken};
    }

    if(config.method === 'GET') {
      queryParams = { ...queryParams,  ...data }
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
      };
    } 
    
    if(config.method === 'POST') {
      config.data = data;
    } 
    config.params = { ...queryParams };
    // const urlWithQueryParams = `${url}${queryStr}`;
    const requestConfig = getInstanceConfig(config)
    const res = await axiosInstance(requestConfig);
    return {
      response: res.data,
      error: undefined,
    };
  } catch(e) {
    console.error('error',e.message);
    return {
      response: undefined,
      error: e.message,
    };
  } 
}

function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
}

const fileUploader = async (url, data, reqConfig = {}) => {
  try{
    console.log('fileUploader', data)
    const authToken = authService.getAuthToken();
    const config = {
      method: 'POST',
      headers: {'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)},
      ...reqConfig
    }
    let queryParams = {};
    const requestName = url.split('/').pop();
    if(requestName){
      queryParams = {...queryParams, CPKP: requestName};
    }
    // const queryStr = config.method === 'GET' ? qs.stringify(data, {
    //   addQueryPrefix: true,
    //   encode: false,
    // }) : '';
    config.url = url;
    if(authToken){
      config.headers = { ...config.headers,  'authToken': authToken};
    }

    // if(config.method === 'GET') {
    //   queryParams = { ...queryParams,  ...data }
    //   config.paramsSerializer = function (params) {
    //     return qs.stringify(params, {arrayFormat: 'brackets'})
    //   };
    // } 
    config.data = getFormData(data);
    config.params = { ...config.params, ...queryParams };
    // const urlWithQueryParams = `${url}${queryStr}`;
    const requestConfig = getInstanceConfig(config)
    const res = await axiosInstance(requestConfig);
    return {
      response: res.data || {},
      error: undefined,
    };
  } catch(e) {
    console.error('error',e.message);
    return {
      response: undefined,
      error: e.message,
    };
  } 
}


export { dataFetcher, axiosInstance, fileUploader };
