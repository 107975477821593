import React from 'react';
import DefaultLayout from "./DefaultLayout";

const LayoutWithHeader = ({children}) => {
  return (
    <DefaultLayout navbar={false}>
      {children}
    </DefaultLayout>
  );
}
 
export default React.memo(LayoutWithHeader);
