// Payment Providers
export const PAYMENT_GATEWAY = {
  PAYTM: 'paytm',
  RAZORPAY: 'razorPay',
  EASEBUZZ: 'easeBuzz'
};

export const paymentModes = [
  { name: 'Credit Card', value: 'CC'},
  { name: 'Debit Card', value: 'DC'},
  { name: 'UPI', value: 'UPI'},
  { name: 'Internet Banking', value: 'NB'},
];

let _paymentModesObj = {};
[...paymentModes].forEach(mode => {
  _paymentModesObj[mode.value] = mode.name;
});
export const paymentModesObj = _paymentModesObj;