class AuthService {
  #authToken;
  constructor() {
    this.#authToken = null
  }

  getAuthToken() {
    return this.#authToken;
  }

  setAuthToken(token) {
    this.#authToken = token;
    window.sessionStorage.setItem("pgc$token", token);
  }
}
export default new AuthService();
