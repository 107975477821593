import React, { useEffect, useState, useCallback } from 'react'
import { Box, Typography, Divider, Paper, Grid, Button } from '@mui/material';
import { checkNoticeStatus, cancelCheckoutNotice } from 'services/checkoutNotice';
import { useSnackbar } from 'notistack';
import CreateNotice from './CreateNotice';

const CheckoutNotice = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [noticeData, setNoticeData] = useState(null);
  const [showUpdateNotice, setShowUpdateNotice] = useState(null);

  useEffect(() => {
    getNoticeStatus();
  }, []);

  function getNoticeStatus() {
    checkNoticeStatus().then(({response, error}) => {
      setShowUpdateNotice(false);
      if(error){
        setNoticeData(null);
      } else if(response){
        setNoticeData({...(response || {})});
      }else{
        setNoticeData(null);
      }
    });
  }

  const onNoticeSubmit = useCallback(
    () => {
      getNoticeStatus();
    },
    [],
  );

  const onNoticeCancel = () => {
    cancelCheckoutNotice().then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        enqueueSnackbar((response?.message || 'Notice cancelled'), {variant: 'success'});
        getNoticeStatus();
      }
    });
  }

  return (
    <Paper sx={{ minWidth: 275, py: 2, px: 3, minHeight: "calc(100vh - 100px )" }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{mb: 1}}>
        Checkout Notice
      </Typography>
      <Divider />
      <Box noValidate  sx={{ mt: 2 }} >
        <Grid container columnSpacing={2} rowSpacing={2} justifyContent="center">
          <Grid item xs={12} sm={12} md={6} lg={5}  >
            { !noticeData || (noticeData && showUpdateNotice) ? (
              <CreateNotice noticeData={noticeData} onNoticeSubmit={onNoticeSubmit} /> 
            ) : (
              <>
              <Typography variant="h5" component="h5"  sx={{ mt: 2, color: '#b79a04' }}>
                You seem to have already provided a notice.<br /> Please choose what you'd whish to do with it.
              </Typography>
              <Grid container  rowSpacing={2}  justifyContent="center" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <Button color="error"  variant="contained" onClick={onNoticeCancel}>Cancel Notice</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <Button color="success"  variant="contained" onClick={() => setShowUpdateNotice(true)}>Update Notice</Button>
                </Grid>
              </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
 
export default CheckoutNotice;
