import { Box, List, ListItem, Typography, Link, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";

const Footer = () => {
  return (
    <Box>
      <List sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', '.MuiListItem-root': { justifyContent: 'center', width: 'auto', px: '10px', py: '6px'}}}>
        <ListItem>
          <Link fontSize="small" color={grey[600]} underline='hover' href="/about/" target="_blank" rel="noopener">
            About
          </Link>
        </ListItem>
        <Divider orientation="vertical" variant="middle" flexItem  sx={{ height: '10px', alignSelf: 'center'}} />
        <ListItem>
          <Link fontSize="small" color={grey[600]} underline='hover' href="/pricing/" target="_blank" rel="noopener">
            Pricing
          </Link>
        </ListItem>
        <Divider orientation="vertical" variant="middle" flexItem  sx={{ height: '10px', alignSelf: 'center'}} />
        <ListItem >
          <Link fontSize="small" color={grey[600]} underline='hover' href="/termsofuse/" target="_blank" rel="noopener">
            Terms
          </Link>
        </ListItem>
        <Divider orientation="vertical" variant="middle" flexItem  sx={{ height: '10px', alignSelf: 'center'}} />
        <ListItem>
          <Link fontSize="small" color={grey[600]} underline='hover' href="/privacy/" target="_blank" rel="noopener">
            Privacy
          </Link>
        </ListItem>
        <Divider orientation="vertical" variant="middle" flexItem  sx={{ height: '10px', alignSelf: 'center'}} />
        <ListItem>
          <Link fontSize="small"  color={grey[600]} underline='hover' href="/contact-us/" target="_blank" rel="noopener">
            Contact
          </Link>
        </ListItem>
      </List>
      <Copyright />
    </Box>
  );
}
 
export default Footer;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="primary" underline='hover' href="https://pgcloud.in/" target="_blank" rel="noopener">
        PG Cloud
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
