import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import {  TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import {confirmRentPayment} from 'services/payRent';

export const paymentModes = [
  { name: 'Card', value: 'CARD'},
  { name: 'Cash', value: 'CASH'},
  { name: 'Cheque', value: 'CHEQUE'},
  { name: 'NEFT/IMPS', value: 'NEFT_IMPS'},
  { name: 'UPI', value: 'UPI'},
]


const PaymentConfirmation = ({paymentId, onPaymentConfirmSuccess}) => {
  const defaultFormFields = {
    referenceNumber: '',
    bank: '',
    paymentMode: '',
    paymentDate: new Date()
  };
  const { enqueueSnackbar } = useSnackbar();
  // const [paymentData, setPaymentData] = useState({ ...defaultFormFields });
  const [dateValue, setDateValue] = useState(new Date());

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
  };

  const { handleSubmit, control, formState: { errors }, reset } = useForm({
    mode: 'onTouched',
    defaultValues: {...defaultFormFields}
  });

  const onSubmit = (data) => {
    updatePaymentData(data);
  }

  function updatePaymentData(data) {
    confirmRentPayment({...data, paymentId, paymentDate: format(dateValue, 'dd-MM-yyyy')}).then(({response, error}) =>{
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        reset({...defaultFormFields});
        enqueueSnackbar(('Payment details submitted successfully'), {variant: 'success'});
        onPaymentConfirmSuccess();
      }
    });
  }

  return (
    <Box noValidate component="form"  onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }} >
      <Grid container columnSpacing={2} rowSpacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}  >
          <Controller
            control={control}
            name="referenceNumber"
            id="ref_no"
            rules={{ required: "Enter Reference Number" }}
            render={({field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                size="small"
                autoFocus
                label="Reference Number"
                required
                error={errors.referenceNumber?.type === 'required'}
                // helperText={errors.firstName?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <Controller
            control={control}
            name="bank"
            id="bank_name"
            rules={{ required: "Enter Bank Name" }}
            render={({field}) => (
              <TextField
                {...field}
                margin="normal"
                label="Bank Name"
                required
                error={errors.bank?.type === 'required'}
                // helperText={errors.lastName?.message}
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}  >
          <FormControl  size="small" fullWidth sx={{ minWidth: 120, }} 
            required
            error={errors.paymentMode?.type === 'required'}
          >
            <InputLabel id="payment-mode-label" >Payment Mode</InputLabel>
            <Controller
            required
            control={control}
            name="paymentMode"
            id="paymentMode"
            rules={{ required: "Select payment mode" }}
            render={({field }) => (
              <Select
                {...field}
                required
                labelId="payment-mode-label"
                // value={issueData.type}
                label="Payment Mode"
              >
                {paymentModes.map(mode => <MenuItem value={mode.value}>{mode.name}</MenuItem>)}
              </Select>
            )}
          />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{mt: 2.5}}>
          <Controller
            control={control}
            name="paymentDate"
            id="payment_date"
            rules={{ required: "Select payment data" }}
            render={({field}) => ( 
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                  // {...field}
                  label="Payment Date"
                  inputFormat="dd/MM/yyyy"
                  clearable={false}
                  maxDate={new Date()}
                  value={dateValue}
                  onChange={handleDateChange}
                  required
                  renderInput={(params) => <TextField required {...field} {...params} size="small" sx={{width: '100%'}} error={errors.paymentDate?.type === 'required'}/>}
                  slotProps={{textField: { fullWidth: true, size: 'small'}}}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          // startIcon={<EditIcon />}
          color="success"
        >
          Submit
        </Button>
      </Grid>
    </Box>
  );
}
 
export default React.memo(PaymentConfirmation);
