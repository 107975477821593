import React, {useState, useEffect} from 'react'
import { getRentReceiptDetails } from 'services/rentReceipts';
import { useSnackbar } from 'notistack';
import { Grid, Paper, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';

const RentReceiptDetails = ({paymentId}) => {
  
  const { enqueueSnackbar } = useSnackbar();
  const [receiptData, setReceiptData] = useState({});

  useEffect(() => {
    getReceiptDetails();
  }, []);

  function getReceiptDetails() {
    getRentReceiptDetails(paymentId).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setReceiptData({...( response || {})})
      }
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 320 }} aria-label="simple table">
          <TableBody sx={{border: '1px solid #ddd'}}>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Date: </TableCell>
              <TableCell align="right"><strong>{receiptData?.paymentDate}</strong></TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Month:</TableCell>
              <TableCell align="right"><strong>{receiptData?.rentPeriod}</strong></TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Payment for:</TableCell>
              <TableCell align="right"><strong>{receiptData?.type}</strong></TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Rental Property:</TableCell>
              <TableCell align="right"><strong>{receiptData?.address}</strong></TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Amount:</TableCell>
              <TableCell align="right"><strong>Rs.{receiptData?.amount}/-</strong></TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Paid By:</TableCell>
              <TableCell align="right"><strong>{receiptData?.inmateName}</strong></TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Received By:</TableCell>
              <TableCell align="right"><strong>{receiptData?.ownerName}</strong></TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Renter PAN:</TableCell>
              <TableCell align="right"><strong>{receiptData?.pan}</strong></TableCell>
            </TableRow>


          </TableBody>
        </Table>
      </TableContainer>
      </Grid>
    </Grid>
  );
}
 
export default React.memo(RentReceiptDetails);
