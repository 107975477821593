import React, { useState, useContext } from 'react';
import { AppBar, Avatar, Button, Divider, ListItemIcon, Stack, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { AppContext, AuthContext } from 'core/context';
import { Link } from 'react-router-dom';
import Notifications from 'components/Notifications';

const MenuItemWithNoHover = styled(MenuItem)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export default function MenuAppBar({hideNavIcon = false}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const { isSideDrawerOpen, updateAppContext } = useContext(AppContext);
  const { isUserLoggedIn, data, userSignout } = useContext(AuthContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleNavbar = () => {
    updateAppContext({
      isSideDrawerOpen: !isSideDrawerOpen
    })
  }

  const handleSignout = () => {
    userSignout();
  }



  return (
    <AppBar position="fixed"  sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {hideNavIcon ? null : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleNavbar}
          >
            { isSideDrawerOpen ? <CloseIcon /> : <MenuIcon  />}
          </IconButton>
        )}
        <Box  sx={{ flexGrow: 1 }}>
          <Avatar
              alt="PG Cloud"
              src={`${process.env.REACT_APP_PATH}/assets/images/logo.png`}
              sx={{ width: 64, height: 'auto' }}
              variant="square"
            />
        </Box>
        <div>
          <Notifications />
          {isUserLoggedIn && (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}  
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 0,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Stack>
                  <Typography variant="body1" sx={{ fontWeight: '600'}}>
                    {data?.name} 
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {data?.pgCloudId}
                  </Typography>
                  <Typography variant="subtitle2" color="secondary"sx={{fontSize: '0.7rem'}} >
                    {data?.resellerEntityName || data?.email}
                  </Typography>
                  </Stack>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} component={Link}to="/profile">
                  <ListItemIcon>
                    <AccountBoxIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItemWithNoHover>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleSignout}
                    color="error"
                    sx={{textTransform: 'none', color: '#000'}}
                    startIcon={<PowerSettingsNewIcon />}
                  >
                    Logout
                  </Button>
                </MenuItemWithNoHover>
              </Menu>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
