import { getAppStatus } from "core/services/auth";
import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext, AppContext } from 'core/context';
import authService from "core/services/authService";

const AppLoading = () => {

  const authContext = useContext(AuthContext);
  const appContext = useContext(AppContext);

  function initAppState() {
    getAppStatus().then(({response, error}) => {
      if(error){
        <Navigate to="/signin/" replace={true} />;
        return;
      }
      const authToken = authService.getAuthToken();
      authContext.updateAuthContext({
        isUserLoggedIn: true,
        authToken,
        inmateId: response?.inmateDetails?.inmateUuid,
        data: {
          name: `${response?.inmateDetails?.name}`,
          pgCloudId: response?.inmateDetails?.pgCloudId,
          pgName: response?.inmateDetails?.pgName,
        }
      });
      appContext.updateAppContext({
        paymentNotification: [
          ...(response?.paymentNotifications || [])
        ]
      })
    });
  }

  useEffect(() => {
    initAppState();
  }, []);
  

  return ('');
}
 
export default AppLoading;