import React, {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import authService from 'core/services/authService';

const defaults = {
  isUserLoggedIn: false,
  authToken: null,
  userId: null,
  data: {}
};
function readAuthDataFromLocalStorage() {
  try{

    let authToken = window.sessionStorage.getItem("pgc$token");
    authService.setAuthToken(authToken || '');

    if(window.sessionStorage.getItem("rpgmUserInfo")){
      const storedData = JSON.parse(window.sessionStorage.getItem("rpgmUserInfo"));
     
      return {
        ...defaults,
        ...storedData,
        authToken,
        isUserLoggedIn: false
      }
    } else {
      return {...defaults, authToken};
    }
  } catch(e) {
    return defaults;
  }
}

const AuthContext = React.createContext({...readAuthDataFromLocalStorage()});

const AuthContextProvider = (props) => {
  const [context, setContext] = useState({...readAuthDataFromLocalStorage()});
  let navigate = useNavigate();

  useEffect(() => {
    authService.setAuthToken(context?.authToken || '');
  }, [context]);

  const updateAuthContext = ( contextValues = {}) => {
    const data = {
      ...context,
      ...contextValues
    }
    window.sessionStorage.setItem("rpgmUserInfo", JSON.stringify(data));
    setContext(data);
  }

  const userSignout = () => {
    setContext({...defaults});
    window.sessionStorage.setItem("rpgmUserInfo", JSON.stringify(defaults));
    window.sessionStorage.removeItem('pgc$token');
    navigate('/signin/', {replace: true});
  }

  return (
    <AuthContext.Provider value={{...context, updateAuthContext, userSignout}}>
      {props.children}
    </AuthContext.Provider>
  );
}
 
export default AuthContextProvider;
export { AuthContext };
