const NavbarMenuItems = [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    url: '/overview/',
  },
  {
    name: 'Management',
    subHeading: true,
  },
  {
    name: 'Pending Payments',
    icon: 'credit_card',
    url: '/rent/',
  },
  {
    name: 'Payments',
    icon: 'receipt',
    url: '/rent-receipts/',
  },
  {
    name: 'Food Register',
    icon: 'restaurant',
    url: '/food-register/',
  },
  {
    name: 'Issues',
    icon: 'report',
    url: '/issues/',
  },
  {
    name: 'Checkout Notice',
    icon: 'hail',
    url: '/checkout-notice/',
  },
  // {
  //   name: 'Employees',
  //   icon: 'supervised_user_circle',
  //   group: true,
  //   roles: ['admin', 'manager'],
  //   items: [
  //     {
  //       name: 'Search',
  //       icon: 'person_search',
  //       url: '/employees/search/'
  //     },
  //     {
  //       name: 'Add',
  //       icon: 'person_add_alt',
  //       url: '/employees/add/'
  //     },
  //   ]
  // },
  // {
  //   name: 'Users',
  //   icon: 'groups',
  //   group: true,
  //   roles: ['admin', 'manager', 'employee'],
  //   items: [
  //     {
  //       name: 'Search',
  //       icon: 'person_search',
  //       url: '/user/search/'
  //     },
  //     {
  //       name: 'Add',
  //       icon: 'group_add',
  //       url: '/user/add/'
  //     },
  //   ]
  // },
  // {
  //   name: 'Manage PG',
  //   icon: 'maps_home_work',
  //   group: true,
  //   roles: ['admin', 'manager', 'employee'],
  //   items: [
  //     {
  //       name: 'Search',
  //       icon: 'manage_search',
  //       url: '/pg/search/'
  //     },
  //     {
  //       name: 'Add',
  //       icon: 'house',
  //       url: '/pg/add/'
  //     },
  //   ]
  // },
];

export default NavbarMenuItems;
