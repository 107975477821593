import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import {  TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
// import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { getIssueDetails, createIssue, updateIssue } from 'services/issues';

const CreateUpdateIssue = ({issueId = null, onSuccess}) => {
  const defaultFormFields = {
    title: '',
    type: 'Amenities',
    description: '',
    createdOn: '',
    status: '',
    comments: ''
  };
  const { enqueueSnackbar } = useSnackbar();
  const [isEditMode] = useState(issueId ? true : false);
  const [issueData, setIssueData] = useState({ ...defaultFormFields });

  const { handleSubmit, control, formState: { errors }, reset } = useForm({
    mode: 'onTouched',
    defaultValues: {...defaultFormFields}
  });

  useEffect(() => {
    if(isEditMode){
      getIssueDetails(issueId).then(({response, error}) => {
        if(error){
          enqueueSnackbar((error?.message || error), {variant: 'error'});
        } else {
          setIssueData({ ...(response || {})});
          reset({...(response || {})});
        }
      });
    }
  }, []);

  const onSubmit = (data) => {
    if(isEditMode){
      updateIssueDetails(data);
    } else {
      createNewIssue(data)
    }
  }

  function createNewIssue(data={}) {
    const {title, type, description} = data;
    const postData={title, type, description}
    createIssue(postData).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        enqueueSnackbar((response?.message || 'Issue has been created'), {variant: 'success'});
        reset({...defaultFormFields});
        onSuccess();
      }
    });
  }

  function updateIssueDetails(data={}) {

    updateIssue({
      ...data,
      status: 'closed'
    }).then(({response, error}) => {
      if(error){
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        enqueueSnackbar((response?.message || 'Issue updated'), {variant: 'success'});
        reset({...defaultFormFields});
        onSuccess();
      }
    });
  }

  return (
    <Box noValidate component="form"  onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }} >
      <Grid container columnSpacing={2} rowSpacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={6} lg={5}  >
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}  >
              <Controller
                control={control}
                name="title"
                id="title"
                rules={{ required: "Enter issue title" }}
                render={({field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    size="small"
                    autoFocus
                    label="Title"
                    required
                    InputProps={{
                      readOnly: isEditMode,
                    }}
                    error={errors.title?.type === 'required'}
                    // helperText={errors.firstName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}  >
              <FormControl  size="small" fullWidth sx={{ minWidth: 120, }} 
                required
              >
                <InputLabel id="issue-label">Issue Type</InputLabel>
                <Controller
                required
                control={control}
                name="type"
                id="type"
                rules={{ required: "Enter issue type" }}
                render={({field }) => (
                  <Select
                    {...field}
                    inputProps={{
                      readOnly: isEditMode,
                    }}
                    required
                    labelId="issue-label"
                    // value={issueData.type}
                    label="Issue Type"
                  >
                    <MenuItem value="Amenities">Amenities</MenuItem>
                    <MenuItem value="Food">Food</MenuItem>
                    <MenuItem value="Electrical">Electrical</MenuItem>
                    <MenuItem value="Plumbing">Plumbing</MenuItem>
                    <MenuItem value="Pests">Pests</MenuItem>
                    <MenuItem value="Cleaning">Cleaning</MenuItem>
                    <MenuItem value="Bed">Bed</MenuItem>
                    <MenuItem value="Room">Room</MenuItem>
                    <MenuItem value="Security">Security</MenuItem>
                    <MenuItem value="Theft">Theft</MenuItem>
                    <MenuItem value="Power">Power</MenuItem>
                    <MenuItem value="PG">PG</MenuItem>
                  </Select>
                )}
              />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Controller
                control={control}
                name="description"
                id="description"
                
                rules={{ required: "Enter issue description" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Description"
                    required
                    error={errors.description?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={6}
                    InputProps={{
                      readOnly: isEditMode,
                    }}
                  />
                )}
              />
            </Grid>
            { isEditMode ? (
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Controller
                  control={control}
                  name="createdOn"
                  id="creation_date"
                  // rules={{ required: "Enter issue type" }}
                  render={({field}) => (
                    <TextField
                      {...field}
                      margin="normal"
                      label="Created On"
                      // required
                      error={errors.createdOn?.type === 'required'}
                      // helperText={errors.lastName?.message}
                      size="small"
                      fullWidth
                      InputProps={{
                        readOnly: isEditMode,
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null}
            { isEditMode ? (
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Controller
                  control={control}
                  name="comments"
                  id="comments"
                  
                  rules={{ required: "Enter comments" }}
                  render={({field}) => (
                    <TextField
                      {...field}
                      margin="normal"
                      label="Comments"
                      required
                      error={errors.comments?.type === 'required'}
                      // helperText={errors.lastName?.message}
                      size="small"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={6}
                      InputProps={{
                        readOnly: issueData.status === 'closed',
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              { isEditMode ? (
                issueData.status === 'open' ? (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // startIcon={<EditIcon />}
                    color="success"
                  >
                    Resolve
                  </Button>
                ) : null) : (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, textTransform: 'inherit' }}
                    startIcon={<AddIcon />}
                    color="primary"
                  >
                    Create
                  </Button>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
 

export default CreateUpdateIssue;
