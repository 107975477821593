import React from 'react';
import { 
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Icon
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const NavListItem = ({menuItem, sx = {}}) => {

  const  {name, icon, url} = menuItem;

  /** Hooks */
  const  { pathname } = useLocation();

  return (
    <ListItemButton component={NavLink} to={url} key={name}  selected={pathname === url} sx={{ py:1.5, pl: 2, ...sx }}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          fontSize: 14,
          // fontWeight: 'light',
          lineHeight: '18px',
          color: '#637381',
        }}
      />
    </ListItemButton>
  );
}
 
export default React.memo(NavListItem);
