import React, { useContext } from 'react';
import { Drawer as BaseDrawer,
  Toolbar,
  Box,
  // List,
  // ListItem,
  // ListItemText,
  // ListItemIcon,
  // Icon
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AppContext } from 'core/context';
import NavbarMenuItems from 'utils/navbarItems';
import NavList from './NavList';

const drawerWidth = 240;
const defaultNavbarMenuItems = [];
const menuItems = [
  ...defaultNavbarMenuItems,
  ...NavbarMenuItems
];

const Drawer = styled(BaseDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth, 
      marginLeft: `-${drawerWidth}px`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: drawerWidth,
        // width: theme.spacing(7),
        // [theme.breakpoints.up('sm')]: {
        //   width: theme.spacing(9),
        // },
        marginLeft: 0,
      }),
    },
    '& .Mui-selected': {
      color: theme.palette.primary.main,
      borderLeft: `6px solid ${theme.palette.primary.main}`,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .Mui-selected .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
      // backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    // '& .MuiListItemButton-root:hover': {
    //   color: theme.palette.primary.main,
    //   // borderRight: `6px solid ${theme.palette.primary.main}`,
    //   backgroundColor: 'rgba(0, 0, 0, 0.04)',
    // },

  }),
);



const Navbar = () => {
  
  /* Hooks */
  const { isSideDrawerOpen } = useContext(AppContext);

  return (
    <Drawer variant="permanent" open={isSideDrawerOpen}>
      <Toolbar />
      <Box sx={{ overflow: 'hidden' }}>
        <NavList menuItems={menuItems} isSideDrawerOpen={isSideDrawerOpen} />
      </Box>
    </Drawer>
  );
};
 
export default Navbar;
