import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, Paper, Accordion, AccordionDetails, AccordionSummary, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Link, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid, Card, CardContent, Stack, Divider} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getPaymentModes, payRentOnline, fetchPaymentDetails } from 'services/payRent';
import { useSnackbar } from 'notistack';
import { formatCurrency, initPaymentFlow } from 'utils/common';
import PaymentStatusMessage from 'components/PaymentStatusMessage';


const PaymentDetails = ({paymentOptions=[], paymentId, refreshPayments}) => {
  const [expanded, setExpanded] = useState(false);
  const [paymentModes, setPaymentModes] = useState([]);
  const [paymentMode, setPaymentMode] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [rentTxnData, setRentTxnData] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const paymentTypes = useMemo(() => {
    const data = {};
    paymentOptions.forEach((element) => {
      data[element.type] = element
    });
    return data;
  }, [paymentOptions]);
  
  const loadPaymentModes = useCallback(() => {
    getPaymentModes().then(({response, error}) => {
      if(error){
        setPaymentModes([]);
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setPaymentModes([...(response || [])]);
      }
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if(paymentTypes['Payment Gateway']) {
      loadPaymentModes();
    }
    return () => {}
  }, [loadPaymentModes, paymentTypes])

  const updatePaymentStatus = useCallback((txnData) => {
    setRentTxnData({...txnData});
  }, []);

  // When payment success
  const successHandler = useCallback((data) => {
      updatePaymentStatus(data);
      refreshPayments();
  }, [updatePaymentStatus, refreshPayments]);
  
  // When payment failed
  const failureHandler = useCallback((data) => {
    updatePaymentStatus(data);
  }, [updatePaymentStatus]);
  
  const payOnline = useCallback(() => {
    payRentOnline(paymentId, {params: { paymentMode }}).then(({response, error}) => {
    // Return if any error
    if(error)return enqueueSnackbar(error?.message, {variant: 'error'});
      initPaymentFlow(response, successHandler, failureHandler)
    });
  }, [paymentId, paymentMode, enqueueSnackbar, successHandler, failureHandler]);

  const resetTransactionData = useCallback(() => {
    setRentTxnData(null);
  }, []);

  // get payment details
  const getPaymentDetails = useCallback((paymentId, paymentMode) => {
    fetchPaymentDetails(paymentId, {params: { paymentMode }}).then(({response, error}) => {
      // Return if any error
      if(error)return enqueueSnackbar(error?.message, {variant: 'error'});
      setPaymentDetails(response);
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    (paymentId && paymentMode) && getPaymentDetails(paymentId, paymentMode)
    return () => {}
  }, [getPaymentDetails, paymentMode, ])
  

  return paymentOptions?.length === 0 ? <Typography variant='subtitle'>No Data found</Typography> : (

    <Box sx={{backgroundColor: '#E7EBF0', p: 3}}>
      {!rentTxnData ? (
        <>
          {paymentTypes && paymentTypes['UPI'] ? 
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  UPI
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} >
                  <Table sx={{ minWidth: 320 }} aria-label="simple table">
                    <TableBody sx={{border: '1px solid #ddd'}}>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">UPI ID: </TableCell>
                        <TableCell align="right"><strong>{paymentTypes['UPI']?.upiId}</strong></TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">Bank:</TableCell>
                        <TableCell align="right"><strong>{paymentTypes['UPI']?.bank}</strong></TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">Account Holder Name:</TableCell>
                        <TableCell align="right"><strong>{paymentTypes['UPI']?.accountHolderName}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion> 
          : null}
          {paymentTypes && paymentTypes['Net Banking'] ? 
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  IMPS/NEFT
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} >
                  <Table sx={{ minWidth: 320 }} aria-label="simple table">
                    <TableBody sx={{border: '1px solid #ddd'}}>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">Account Number: </TableCell>
                        <TableCell align="right"><strong>{paymentTypes['Net Banking']?.accountNumber}</strong></TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">IFSC:</TableCell>
                        <TableCell align="right"><strong>{paymentTypes['Net Banking']?.ifsc}</strong></TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">Bank:</TableCell>
                        <TableCell align="right"><strong>{paymentTypes['Net Banking']?.bank}</strong></TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">Branch:</TableCell>
                        <TableCell align="right"><strong>{paymentTypes['Net Banking']?.branch}</strong></TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">Account Holder Name:</TableCell>
                        <TableCell align="right"><strong>{paymentTypes['Net Banking']?.accountHolderName}</strong></TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion> 
          : null}
          {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                IMPS/NEFT
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                amet egestas eros, vitae egestas augue. Duis vel est augue.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
          {paymentTypes && paymentTypes['Payment Link'] ? 
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Payment Link
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <Link href={paymentTypes['Payment Link'].paymentLink} target="_blank" rel="noreferrer" underline="none">
                  <Button variant="contained">Pay</Button>
                </Link>
              </AccordionDetails>
            </Accordion> 
          : null}

          {paymentModes?.length > 0 && paymentTypes['Payment Gateway'] ? 
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Pay Online
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <FormControl>
                    <FormLabel id="paymentMode-label" sx={{fontSize: 14, fontWeight: 300, color: 'secondary' }}>How would you like to pay?</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="paymentMode-label"
                      name="paymentMode"
                      value={paymentMode}
                      onChange={(event) => setPaymentMode(event.target.value)}
                      >
                      {paymentModes.map(mode => <FormControlLabel value={mode.id} key={mode.id} control={<Radio size="small" />} label={mode.name} />)}
                    </RadioGroup>
                  </FormControl>
                </Box>
                {paymentDetails && <> <Divider color="blue" sx={{my: 1}} />
                  <PaymentDetailsCard details={paymentDetails} /> </>}
                {paymentMode && <Box mt={1} textAlign="center">
                  <Button variant='contained'  style={{textTransform: 'none'}} onClick={payOnline}>Proceed to pay</Button>
                </Box>}
              </AccordionDetails>
            </Accordion> 
          : null}
        </> 
      ): null}

      {rentTxnData ? (
        <Grid item xs={12} style={{backgroundColor: '#fff'}} >
          <PaymentStatusMessage txnData={rentTxnData} reset={resetTransactionData} />
        </Grid>
      ) : null}
    </Box>
  );
}
 
function PaymentDetailsCard({details={}}) {
  const { amount, processingFeePercent, processingFee, gstPercent, gst, convenienceFee } = details;

  const total = amount + processingFee + gst + convenienceFee;

  return (
    <Card elevation={0} >
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary" variant='body2'>Amount</Typography>
          <Typography variant='body1' fontWeight={500}>{formatCurrency(amount)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary" variant='body2'>Processing fee @{processingFeePercent}%</Typography>
          <Typography variant='body1' fontWeight={500}>{formatCurrency(processingFee)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary" variant='body2'>Tax @{gstPercent}%</Typography>
          <Typography variant='body1' fontWeight={500}>{formatCurrency(gst)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary" variant='body2'>Convenience fee</Typography>
          <Typography variant='body1' fontWeight={500}>{formatCurrency(convenienceFee)}</Typography>
        </Stack>
        <Divider sx={{my: 1}} />
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary" fontWeight={800} variant='body2'>Total</Typography>
          <Typography variant='h6' color="green" >{formatCurrency(total)}</Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default PaymentDetails;
