import { useEffect, useMemo, useState } from 'react';
import { axiosInstance } from 'core/services/httpRequest';


export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
    
  const interceptors = useMemo(() => {
    const inc = () => setCounter(counter => counter + 1);
    const dec = () => setCounter(counter => counter - 1);
    //getUserSuggestionsByMobile
    return ({
      request: config => (inc(), config),
      response: response => (dec(), response),
      error: error => (dec(), Promise.reject(error)),
    });
  }, []); // create the interceptors
  
  useEffect(() => {
    // add request interceptors
    const reqInterceptor = axiosInstance.interceptors.request.use(function(request){
      if(request.url.indexOf('getUserSuggestionsByMobile') === -1){
        setCounter(counter => counter + 1);
      }
      return request;
    }, interceptors.error);
    // add response interceptors
    const resInterceptor = axiosInstance.interceptors.response.use(function(response){
      if(response.config.url.indexOf('getUserSuggestionsByMobile') === -1){
        setCounter(counter => counter - 1);
      }
      return response;
    }, interceptors.error);
    return () => {
      // remove all intercepts when done
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);
  
  return [counter > 0];
};
