import PaytmCheckout from "components/payments/paytm";
import RazorpayCheckout from "components/payments/razorPay";
import EaseBuzzCheckout from "components/payments/easebuzz";
import { PAYMENT_GATEWAY } from "./appConstants";

export const mapValueToId = (data = [], mapKey) => {
  if(Array.isArray(data) && data.length){
    return data.map((value, index) => ({...value, id: value[mapKey] || index}))
  }
  return data;
}

// Create the function
export async function AddScript(url) {
  return new Promise((resolve) => {
    
    // resolve if document has script with same url
    if(document.querySelector(`script[src*='${url}']`)){
      return resolve();
    }
    // Add script otherwise
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = script.readystatechange = () => resolve();
    script.crossorigin = "anonymous";
    document.body.appendChild(script);
  });
}

export const currencyFormatter = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', roundingMode: 'ceil' });
export const formatCurrency = (value=0) => currencyFormatter.format(value);

// Launch paytm checkout popup
export function initPaymentFlow(txnData, successHandler, failureHandler){
  // Launch paytm checkout
  if(txnData?.paymentGatewayName === PAYMENT_GATEWAY.PAYTM ){
    const instance = new PaytmCheckout(successHandler, failureHandler);
    instance.launch(txnData);
  }

  //  Launch Razorpay checkout
  else if(txnData?.paymentGatewayName === PAYMENT_GATEWAY.RAZORPAY){
    const instance = new RazorpayCheckout(successHandler, failureHandler);
    instance.launch(txnData);
  }

  //  Launch EaseBuzz checkout
  else if(txnData?.paymentGatewayName === PAYMENT_GATEWAY.EASEBUZZ){
    const instance = new EaseBuzzCheckout(successHandler, failureHandler);
    instance.launch(txnData);
  }
  
}